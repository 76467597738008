import React, { useState } from "react";

// Modal component for displaying the submitted information
const Modal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-1/2">
        <h2 className="text-2xl font-bold mb-4 text-center">Receipt</h2>
        <div className="mb-4">
          <h3 className="text-xl font-semibold">Company Details:</h3>
          <ul className="list-disc ml-5 text-gray-800 dark:text-gray-200">
            {Object.entries(data).map(([key, value]) => (
              <li key={key}>
                <span className="font-bold">
                  {key.replace(/_/g, " ")}: ....................................
                </span>{" "}
                {value.toString()}
              </li>
            ))}
          </ul>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold">Total Amounts:</h3>
          <ul className="list-disc ml-5 text-gray-800 dark:text-gray-200">
            <li>
              <span className="font-bold">Total Card Amount: </span>
              {data.totalCardAmount}
            </li>
            <li>
              <span className="font-bold">Total Stand Amount: </span>
              {data.totalStandAmount}
            </li>
            <li>
              <span className="font-bold">Total Subscription Amount: </span>
              {data.totalSubscriptionAmount}
            </li>
            <li>
              <span className="font-bold">Grand Total: </span>
              {data.grandTotal}
            </li>
          </ul>
        </div>
        <button
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const InputField = ({
  name,
  type,
  label,
  value,
  onChange,
  options,
  error,
  ...rest
}) => {
  return (
    <div className="w-full md:w-1/2 px-4 mb-6">
      <label className="block font-semibold text-lg mb-1 text-gray-700 dark:text-gray-300">
        {label}:
        {type === "select" ? (
          <select
            name={name}
            value={value}
            onChange={onChange}
            className="w-full px-4 py-3 mt-2 rounded-lg shadow-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all"
            {...rest}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            className="w-full px-4 py-3 mt-2 rounded-lg shadow-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all"
            {...rest}
          />
        )}
      </label>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

const TwoStepForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    username: "",
    email: "",
    phone_number: "",
    industry_type: "",
    company_type: "",
    number_of_employees: "",
    number_of_cards: 0,
    number_of_stands: 0,
    card_amount: 0,
    stand_amount: 0,
    subscription_amount: 0,
    profile_image: "",
    preferred_business_name: "",
    reward_amount_per_score: 0,
    is_hq_company: "", // Will be set to true or false based on input
    number_of_locations: "",
    subscription_interval: "", // default subscription interval
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
  const [errors, setErrors] = useState({}); // State to store validation errors
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNext = async () => {
    // Password validation before proceeding to the next step
    if (formData.password !== formData.confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: "Passwords do not match!",
      });
      return;
    }
    try {
      const response = await fetch(
        "https://zola.technology/api/company/signup/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        // If there are validation errors, set the errors state
        setErrors(data);
      } else {
        // Store the username in localStorage on successful response
        localStorage.setItem("username", formData.username);

        setStep(2);
        setErrors({}); // Clear any previous errors
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Convert string values to numbers before calculations
    const number_of_cards = parseFloat(formData.number_of_cards) || 0;
    const card_amount = parseFloat(formData.card_amount) || 0;
    const number_of_stands = parseFloat(formData.number_of_stands) || 0;
    const stand_amount = parseFloat(formData.stand_amount) || 0;
    const subscription_amount = parseFloat(formData.subscription_amount) || 0;
    const number_of_locations = parseFloat(formData.number_of_locations) || 1; // Default to 1 location if not provided

    // Calculate total amounts
    const totalCardAmount = number_of_cards * card_amount;
    const totalStandAmount = number_of_stands * stand_amount;
    const totalSubscriptionAmount = subscription_amount * number_of_locations;
    const grandTotal =
      totalCardAmount + totalStandAmount + totalSubscriptionAmount;

    // Determine transaction type
    let transactionType = "";
    if (totalCardAmount > 0 && totalSubscriptionAmount > 0) {
      transactionType = "both";
    } else if (totalSubscriptionAmount > 0) {
      transactionType = "subscription";
    } else {
      transactionType = "payment";
    }

    const paymentData = {
      username: formData.username,
      unit_amount: grandTotal - totalSubscriptionAmount,
      phone_number: formData.phone_number,
      subscription_interval: formData.subscription_interval,
      subscription_amount: totalSubscriptionAmount,
      transaction_type: transactionType,
    };

    try {
      // Submit payment request
      const response = await fetch(
        "https://zola.technology/api/company/make-payment/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setErrors(data); // Display any errors
        setLoading(false);
      } else {
        // Pass the total amounts to the formData for the modal
        setFormData({
          ...formData,
          totalCardAmount,
          totalStandAmount,
          totalSubscriptionAmount,
          grandTotal,
        });

        // Extract the URL received from the response data
        const receivedUrl = data.url; // Assuming the URL is in `data.url`

        // Prepare email data
        const emailData = {
          subject: "Complete registration",
          message: `Please complete your registration using the following link: ${receivedUrl}`,
          recipients: [formData.email], // The email entered when creating the account
        };

        // Send email with the received URL
        const emailResponse = await fetch(
          "https://zola.technology/api/send-bulk-emails/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          }
        );

        if (!emailResponse.ok) {
          const emailErrorData = await emailResponse.json();
          console.error("Error sending email:", emailErrorData);
        }

        // Perform PATCH update for number of cards and number of stands
        const updateData = {
          number_of_cards: formData.number_of_cards,
          number_of_stands: formData.number_of_stands,
        };

        const patchResponse = await fetch(
          `https://zola.technology/api/company/update/${formData.username}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateData),
          }
        );

        if (!patchResponse.ok) {
          const patchErrorData = await patchResponse.json();
          console.error("Error updating company data:", patchErrorData);
        }

        setModalOpen(true); // Show the modal on successful form submission
        setErrors({}); // Clear any previous errors
      }
    } catch (error) {
      console.error("Error submitting payment:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const companyFields = [
    {
      name: "first_name",
      type: "text",
      label: "First name*",
      required: true,
      maxLength: 30,
      minLength: 1,
    },
    {
      name: "last_name",
      type: "text",
      label: "Last name*",
      required: true,
      maxLength: 30,
      minLength: 1,
    },
    {
      name: "company_name",
      type: "text",
      label: "Company name*",
      required: true,
      minLength: 1,
    },
    {
      name: "username",
      type: "text",
      label: "Username*",
      required: true,
      maxLength: 20,
      minLength: 6,
    },
    {
      name: "email",
      type: "email",
      label: "Email*",
      required: true,
      maxLength: 255,
      minLength: 1,
    },
    {
      name: "phone_number",
      type: "text",
      label: "Phone number*",
      required: true,
      pattern: "^\\+?1?\\d{9,15}$",
      maxLength: 15,
      minLength: 10,
    },
    {
      name: "industry_type",
      type: "text",
      label: "Industry type*",
      required: true,
    },
    {
      name: "company_type",
      type: "select",
      label: "Company type*",
      required: true,
      options: [
        { label: "", value: "" },
        { label: "One Person", value: "one_person" },
        { label: "Team", value: "team" },
      ],
    },
    {
      name: "number_of_employees",
      type: "number",
      label: "Number of employees*",
      required: true,
      min: 0,
    },
    {
      name: "preferred_business_name",
      type: "text",
      label: "Preferred business name",
      required: false,
    },
    {
      name: "is_hq_company",
      type: "select",
      label: "Is this an HQ company?",
      options: [
        { label: "", value: "" },
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      required: true,
    },
    {
      name: "number_of_locations",
      type: "number",
      label: "Number of locations*",
      required: true,
      min: 0,
    },
    {
      name: "password",
      type: "password",
      label: "Password*",
      required: true,
      min: 0,
    },
    {
      name: "confirmPassword",
      type: "password",
      label: "Confirm Password*",
      required: true,
      min: 0,
    },
  ];

  const paymentFields = [
    {
      name: "number_of_cards",
      type: "number",
      label: "Number of cards*",
      required: true,
    },
    {
      name: "number_of_stands",
      type: "number",
      label: "Number of stands*",
      required: true,
    },
    {
      name: "card_amount",
      type: "number",
      label: "Card amount per unit*",
      required: true,
      min: 0,
    },
    {
      name: "stand_amount",
      type: "number",
      label: "Stand amount per unit*",
      required: true,
      min: 0,
    },
    {
      name: "subscription_amount",
      type: "number",
      label: "Subscription amount*",
      required: true,
      min: 0,
    },
    {
      name: "subscription_interval",
      type: "select",
      label: "Subscription interval",
      options: [
        { label: "", value: "" },
        { label: "Month", value: "month" },
        { label: "Year", value: "year" },
      ],
      required: true,
    },
  ];

  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen py-12 ">
      <div className="max-w-5xl mx-auto p-6 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg w-full">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-4">
          Company Registration
        </h2>
        <form onSubmit={handleSubmit} className="w-full">
          {step === 1 && (
            <div className="flex flex-wrap -mx-4">
              {companyFields.map((field) => (
                <InputField
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  value={formData[field.name]}
                  onChange={handleChange}
                  options={field.options || []}
                  error={errors[field.name]} // Display field-specific errors
                />
              ))}
              <div className="w-full">
                <button
                  type="button"
                  onClick={handleNext}
                  className="mt-6 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded w-full"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="flex flex-wrap -mx-4">
              {paymentFields.map((field) => (
                <InputField
                  key={field.name}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  value={formData[field.name]}
                  onChange={handleChange}
                  options={field.options || []}
                  error={errors[field.name]} // Display field-specific errors
                />
              ))}
              <div className="w-full text-center">
                <button
                  type="submit"
                  className={`mt-6 ${
                    loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
                  } text-white font-bold py-2 px-6 rounded w-full`}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          )}
        </form>
      </div>

      {/* Modal for displaying the receipt */}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} data={formData} />
    </div>
  );
};

export default TwoStepForm;
