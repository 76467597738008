import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import useTheme from './useTheme'; // Import the custom hook

const fetchPerformanceData = async (filter) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`https://zola.technology/api/company/employees/performance/get-spread-taps/?filter=${filter}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

const OverallPerformance = () => {
  const [performanceData, setPerformanceData] = useState(null);
  const [filter, setFilter] = useState('week');
  const [rangeLabel, setRangeLabel] = useState('');
  const isDarkMode = useTheme(); // Use the custom hook

  useEffect(() => {
    const getData = async () => {
      const data = await fetchPerformanceData(filter);
      setPerformanceData(data);

      const range = `${data.periods[0].period} - ${data.periods[data.periods.length - 1].period}`;
      setRangeLabel(range);
    };

    getData();
  }, [filter]);

  if (!performanceData) {
    // Skeleton loading state
    return (
      <div className="p-4 sm:p-6 bg-gray-100 dark:bg-gray-900 rounded-lg max-w-full shadow-lg border border-gray-300 dark:border-gray-700">
        <div className="animate-pulse flex flex-col space-y-4 h-72 sm:h-96">
          <div className="h-full bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 rounded"></div>
        </div>
      </div>
    );
  }

  const labels = performanceData.periods.map(period =>
    new Date(period.period).toLocaleDateString('default', { month: filter === 'year' ? 'short' : undefined, day: 'numeric', weekday: filter === 'week' ? 'short' : undefined })
  );

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  gradient.addColorStop(0, 'rgba(0, 0, 255, 0.3)');
  gradient.addColorStop(1, 'rgba(0, 0, 255, 1)');

  const data = {
    labels,
    datasets: [
      {
        label: 'Taps',
        data: performanceData.periods.map(period => period.taps),
        backgroundColor: gradient,
        borderColor: 'rgba(0, 0, 255, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)', 
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10,
          color: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)', 
        },
        grid: {
          borderDash: [5, 5], 
        },
      },
    },
  };

  return (
    <div className="p-4 sm:p-6 bg-gray-100 dark:bg-gray-900 rounded-lg max-w-full shadow-lg border border-gray-300 dark:border-gray-700">
      <h2 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-100 mb-2">Performance</h2>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 sm:mb-6 space-y-4 sm:space-y-0">
        <h3 className="text-base sm:text-lg font-medium text-gray-700 dark:text-gray-200">
          Overall Performance <span className="text-green-500">4%</span>
        </h3>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          <button className="border border-gray-300 dark:border-gray-600 px-2 py-1 sm:px-4 sm:py-2 rounded-lg dark:bg-gray-700 dark:text-gray-100">
            {rangeLabel}
          </button>
          <select
            className="border border-gray-300 dark:border-gray-600 px-2 py-1 sm:px-4 sm:py-2 rounded-lg dark:bg-gray-700 dark:text-gray-100"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="week">Week</option>
            <option value="year">Year</option>
          </select>
        </div>
      </div>
      <p className="text-sm text-gray-600 dark:text-gray-400 mb-2 sm:mb-4">{filter === 'week' ? 'This Week' : filter === 'month' ? 'This Month' : 'This Year'}</p>
      <div className="w-[90%] lg:w-full h-64 sm:h-80 lg:h-96">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default OverallPerformance;
