// PerformanceChart.jsx
import React from 'react';
import { Line } from 'react-chartjs-2'; // Import Line instead of Bar
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend);

const PerformanceChart = ({ branches }) => {
  const branchesDummies = [
    { performance: 80 },
    { performance: 65 },
    { performance: 50 },
    { performance: 90 },
    { performance: 30 },
  ];
  // Prepare data for Chart.js
  const data = {
    labels: branches.map((branch) => branch.name),
    datasets: [
      {
        label: 'Performance (%)',
        // data: branches.map((branch) => parseInt(branch.performance)),
        data: branchesDummies.map((branch) => branch.performance),
        backgroundColor: 'rgba(52, 211, 153, 0.5)',
        borderColor: branches.map((branch) =>
          parseInt(branch.performance) > 75
            ? '#34D399' // Green for high performance
            : parseInt(branch.performance) > 50
            ? '#FBBF24' // Yellow for medium performance
            : '#EF4444' // Red for low performance
        ),
        borderWidth: 2,
        fill: true, // Set to true for area under the line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#ffffff',
        },
      },
      title: {
        display: true,
        text: 'Branch Performance Overview',
        color: '#ffffff',
        font: {
          size: 20,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => `${context.raw}% Performance`,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#ffffff',
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        grid: {
          color: '#4B5563',
        },
        ticks: {
          color: '#ffffff',
        },
      },
    },
  };

  return (
    <div className="bg-[#374151] p-4 rounded-lg shadow-lg">
      <Line data={data} options={options} height={300} /> {/* Use Line instead of Bar */}
    </div>
  );
};

export default PerformanceChart;
