import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Members, Billing, Notification, Language, Account } from '../components/AccountSettings/index';
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoGlobeOutline } from "react-icons/io5";
import PaymentPage from './AccountSettings/PaymentDetailsPage';

const AccountSettings = () => {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-00 flex flex-col">
      {/* Account Settings Title */}
      <div className="bg-white dark:bg-gray-800 shadow p-6">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-100">Account Settings</h2>
      </div>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row flex-grow p-6 space-y-6 lg:space-y-0 lg:space-x-6 bg-gray-100 dark:bg-gray-900">
        {/* Sidebar */}
        <div className="w-full lg:w-1/4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="font-bold text-gray-700 dark:text-gray-300">GENERAL SETTINGS</div>
          <ul className="space-y-2 mt-2">
            <li className="text-gray-700 dark:text-gray-300">
              <Link to="/dashboard/setting/" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">
                <IoPersonCircleOutline /> <span>Account</span>
              </Link>
            </li>
            {/* Additional links */}
          </ul>
          <div className="font-bold text-gray-700 dark:text-gray-300 mt-8">WORKSPACE SETTINGS</div>
          <ul className="space-y-2 mt-2">
          
            <li className="text-gray-700 dark:text-gray-300">
              <Link to="/dashboard/setting/billing" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">
                <IoGlobeOutline /> <span>Billing</span>
              </Link>
            </li>
            <li className="text-gray-700 dark:text-gray-300">
              <Link to="/dashboard/setting/update-details" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700">
                <IoGlobeOutline /> <span>Payment Details</span>
              </Link>
            </li>
          </ul>
        </div>

        {/* Dynamic Content */}
        <div className="w-full lg:w-3/4 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <Routes>
            <Route path="/" element={<Account />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/language" element={<Language />} />
            <Route path="/members" element={<Members />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/update-details" element={<PaymentPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
