import { useEffect, useState } from 'react';

const useTheme = () => {
  const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));

  useEffect(() => {
    const handleThemeChange = () => {
      setIsDarkMode(document.documentElement.classList.contains('dark'));
    };

    handleThemeChange(); // Set initial value

    window.addEventListener('change', handleThemeChange);

    return () => window.removeEventListener('change', handleThemeChange);
  }, []);

  return isDarkMode;
};

export default useTheme;
