import {
  FaUsers,
  FaTasks,
  FaRegCalendarAlt,
  FaExclamationTriangle,
  FaClipboardCheck,
  FaBuilding,
  FaChartLine,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import BranchTable from "./BranchTable";
import PerformanceChart from "./PerformanceChart";
import { useContext } from "react";
import { BranchContext } from "../context/BranchContext";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  LabelList,
} from "recharts";

const Dashboard = () => {
  const { branches, employeeTaps } = useContext(BranchContext);

  const totalBranches = employeeTaps.total;
  const totalEmployees = branches.reduce(
    (total, branch) => total + branch.employees,
    0
  );
  const totalIssues = branches.reduce(
    (total, branch) => total + branch.issues,
    0
  );
  const resolvedIssues = branches.filter(
    (branch) => branch.issues === 0
  ).length;
  const averagePerformance = (
    branches.reduce(
      (total, branch) => total + parseFloat(branch.performance),
      0
    ) / branches.length
  ).toFixed(2);

  console.log(employeeTaps)

  const branchEmployeesData = branches.map((branch) => ({
    name: branch.name,
    value: branch.employees,
  }));

  console.log(branchEmployeesData);

  // Updated colors for the pie chart
  const COLORS = [
    "#00C49F", // Green
    "#FFBB28", // Yellow
    "#FF8042", // Orange
    "#8A2BE2", // Blue Violet
    "#FF69B4", // Pink
    "#FF4500", // Orange Red
  ];

  return (
    <div className="bg-[#374151] min-h-screen p-6 text-white">
      <h1 className="text-5xl font-bold mb-8 text-white">
        Headquarters Dashboard
      </h1>
      <p className="text-[#D1D5DB] text-lg mb-10">
        Welcome to the Headquarters Dashboard! Manage and monitor all branch
        activities and performance.
      </p>

      {/* KPI Summary Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
        {/* Total Branches Card */}
        <div className="bg-gradient-to-r from-gray-700 to-gray-600 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-2">
          <h2 className="text-xl font-semibold text-white mb-4 flex items-center">
            <FaBuilding className="mr-2 text-yellow-400" /> Total Branches
          </h2>
          <p className="text-4xl text-white font-extrabold">{totalBranches}</p>
        </div>

        {/* Total Employees Card */}
        <div className="bg-gradient-to-r from-gray-700 to-gray-600 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-2">
          <h2 className="text-xl font-semibold text-white mb-4 flex items-center">
            <FaUsers className="mr-2 text-indigo-400" /> Total Employees
          </h2>
          <p className="text-4xl text-white font-extrabold">{totalEmployees}</p>
        </div>

        {/* Total Issues Card */}
        <div className="bg-gradient-to-r from-gray-700 to-gray-600 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-2">
          <h2 className="text-xl font-semibold text-white mb-4 flex items-center">
            <FaExclamationTriangle className="mr-2 text-red-400" /> Total Issues
          </h2>
          <p className="text-4xl text-white font-extrabold">{totalIssues}</p>
        </div>

        {/* Resolved Issues Card */}
        <div className="bg-gradient-to-r from-gray-700 to-gray-600 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-2">
          <h2 className="text-xl font-semibold text-white mb-4 flex items-center">
            <FaClipboardCheck className="mr-2 text-teal-400" /> Resolved Issues
          </h2>
          <p className="text-4xl text-white font-extrabold">{resolvedIssues}</p>
        </div>

        {/* Average Performance Card */}
        <div className="bg-gradient-to-r from-gray-700 to-gray-600 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-2">
          <h2 className="text-xl font-semibold text-white mb-4 flex items-center">
            <FaChartLine className="mr-2 text-green-400" /> Average Performance
          </h2>
          <p className="text-4xl text-white font-extrabold">{`${averagePerformance}%`}</p>
        </div>
      </div>

      {/* Branch Details Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {branches.map((branch) => (
          <div
            key={branch.id}
            className="bg-[#4B5563] shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out "
          >
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <FaBuilding className="mr-2 text-teal-400" /> {branch.name}
            </h2>
            <div className="mb-4">
              <p className="text-gray-300 flex items-center mb-2">
                <FaUsers className="mr-2 text-indigo-400" /> Employees:{" "}
                {branch.employees}
              </p>
              <p className="text-gray-300 flex items-center mb-2">
                <FaTasks className="mr-2 text-yellow-400" /> Performance:{" "}
                {branch.performance}
              </p>
              <p className="text-gray-300 flex items-center mb-2">
                <FaExclamationTriangle className="mr-2 text-red-400" /> Issues:{" "}
                {branch.issues}
              </p>
              {/* <p className="text-gray-300 flex items-center mb-2">
                <FaRegCalendarAlt className="mr-2 text-blue-400" /> Upcoming
                Event: {branch.upcomingEvents}
              </p> */}
            </div>
            <p className="text-gray-400 italic">
              Recent Activity: {branch.recentActivity}
            </p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 gap-6 mb-8">
        <BranchTable branches={branches} />
        <PerformanceChart branches={branches} />
      </div>
    </div>
  );
};

export default Dashboard;
