import React from 'react';
import { motion } from 'framer-motion';
import { PopupButton } from "react-calendly";

const BookingPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-8">
      <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8">
        <motion.h1
          className="text-3xl font-bold text-center text-gray-800 mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Book a Meeting
        </motion.h1>

        <motion.p
          className="text-lg text-gray-600 mb-4 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          We are excited to help you set up your multi-location account! 
          Please schedule a meeting with us to discuss your needs and ensure 
          a smooth setup process.
        </motion.p>

        <motion.p
          className="text-lg text-gray-600 mb-8 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Click the button below to select a date and time that works for you.
        </motion.p>

        <div className="flex justify-center">
        <div className="mt-8">
          <PopupButton
            url="https://calendly.com/newtonombese1/30min"
            rootElement={document.getElementById("root")}
            text="Schedule For A Meeting"
            className="bg-teal-500 text-white px-6 py-3 rounded-lg hover:bg-teal-400 shadow-lg"
          />
        </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
