import React, { useState } from 'react';

// Survey Builder Component
function Survey() {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ type: 'text', label: '', options: [] });
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddQuestion = () => {
    if (newQuestion.label) {
      setQuestions((prev) => [...prev, newQuestion]);
      setNewQuestion({ type: 'text', label: '', options: [] });
    }
  };

  const handleRemoveQuestion = (index) => {
    setQuestions((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSurveySubmit = (e) => {
    e.preventDefault();
    console.log('Survey Questions:', questions);
    // Here you can handle the survey submission (e.g., save to backend)
  };

  const handlePreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  return (
    <div className="p-8 max-w-lg mx-auto bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-semibold mb-6 text-gray-800 text-center">Create Custom Survey</h1>
      <form className="space-y-6">
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Question Label</label>
          <input
            type="text"
            name="label"
            value={newQuestion.label}
            onChange={handleQuestionChange}
            placeholder="Enter question"
            className="mt-1 p-3 border border-gray-300 text-black rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Question Type</label>
          <select
            name="type"
            value={newQuestion.type}
            onChange={handleQuestionChange}
            className="mt-1 p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500 bg-blue-400"
          >
            <option value="text">Text</option>
            <option value="email">Email</option>
            <option value="radio">Multiple Choice</option>
            <option value="checkbox">Checkbox</option>
            <option value="textarea">Textarea</option>
          </select>
        </div>

        {newQuestion.type === 'radio' || newQuestion.type === 'checkbox' ? (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Options (comma-separated)</label>
            <input
              type="text"
              value={newQuestion.options.join(', ')}
              onChange={(e) => setNewQuestion({ ...newQuestion, options: e.target.value.split(',').map(option => option.trim()) })}
              placeholder="Enter options"
              className="mt-1 p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        ) : null}

        <button
          type="button"
          onClick={handleAddQuestion}
          className="bg-green-500 text-white font-semibold py-2 rounded-md w-full hover:bg-green-600 transition duration-300"
        >
          Add Question
        </button>

        <h2 className="mt-6 text-xl font-bold text-gray-800">Survey Questions</h2>
        <ul className="mt-4 space-y-2">
          {questions.map((question, index) => (
            <li key={index} className="flex justify-between items-center bg-gray-100 p-3 rounded-md shadow-sm">
              <span className="text-gray-700">{question.label} ({question.type})</span>
              <button
                onClick={() => handleRemoveQuestion(index)}
                className="text-red-500 hover:text-red-700 font-semibold"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        <div className="flex space-x-4 mt-4">
          <button
            type="button"
            onClick={handlePreview}
            className="w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Preview Survey
          </button>
          <button
            type="submit"
            onClick={handleSurveySubmit}
            className="w-full bg-blue-600 text-white font-semibold py-2 rounded-md hover:bg-blue-700 transition duration-300"
          >
            Submit Survey
          </button>
        </div>
      </form>

      {/* Preview Modal */}
      {isPreviewOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Survey Preview</h2>
            <ul className="space-y-4">
              {questions.map((question, index) => (
                <li key={index} className="flex flex-col">
                  <span className="text-gray-800 font-semibold">{question.label}</span>
                  {question.type === 'radio' ? (
                    <div className="mt-2">
                      {question.options.map((option, i) => (
                        <label key={i} className="block text-gray-700">
                          <input type="radio" name={`question-${index}`} className="mr-2" />
                          {option}
                        </label>
                      ))}
                    </div>
                  ) : question.type === 'checkbox' ? (
                    <div className="mt-2">
                      {question.options.map((option, i) => (
                        <label key={i} className="block text-gray-700">
                          <input type="checkbox" name={`question-${index}-${i}`} className="mr-2" />
                          {option}
                        </label>
                      ))}
                    </div>
                  ) : (
                    <input type={question.type} placeholder={`Your answer...`} className="mt-2 p-2 border border-gray-300 rounded-md w-full" />
                  )}
                </li>
              ))}
            </ul>
            <div className="mt-4 flex justify-end">
              <button onClick={handleClosePreview} className="bg-red-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-red-600 transition duration-300">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Survey;
