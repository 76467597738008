import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import ColorPicker from "./ColorPicker";

function MobilePreview() {
  const [companyLogo, setCompanyLogo] = useState(
    "https://via.placeholder.com/100x100"
  );
  const [coverImage, setCoverImage] = useState(
    "https://via.placeholder.com/300x200"
  );
  const [welcomeMessage, setWelcomeMessage] = useState("Hi there 👋");
  const [visitMessage, setVisitMessage] = useState(
    "Thanks for being our visitor today!"
  );

  // State for mobile frame background color
  const [bgColor, setBgColor] = useState("gray"); // Default white

  // Calculate text color based on background color
  const getTextColor = (bgColor) => {
    const hex = bgColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "#000000" : "#FFFFFF"; // Return black for light bg, white for dark bg
  };

  const [textColor, setTextColor] = useState(getTextColor(bgColor)); // Default text color based on initial bgColor
  const [newButtonLabel, setNewButtonLabel] = useState(""); // State for new button label

  const buttonColor = (bgColor) => {
    const hex = bgColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? "bg-blue-500" : "bg-blue-600"; // Change button color based on background luminance
  };

  useEffect(() => {
    setTextColor(getTextColor(bgColor)); // Update text color when bgColor changes
  }, [bgColor]);

  // Button States
  // Button States with default background color set to blue
  const [buttons, setButtons] = useState([
    {
      label: "Leave Us A Review",
      visible: true,
      isDefault: true,
      bgColor: "#0000FF",
    },
    {
      label: "Refer Us To A Friend",
      visible: true,
      isDefault: false,
      bgColor: "#0000FF",
    },
    {
      label: "Follow Us On Instagram",
      visible: true,
      isDefault: false,
      bgColor: "#0000FF",
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [newCoverImage, setNewCoverImage] = useState("");
  const [file, setFile] = useState(null);

  const [isFirstTimeUser, setIsFirstTimeUser] = useState(true); // Track if user is first time
  const [isUpdating, setIsUpdating] = useState(false); // Track if the user is updating

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://zola.technology/api/company/profile/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setCompanyLogo(
          data.profile_image || "https://via.placeholder.com/100x100"
        );
      })
      .catch((error) => {
        console.error("There was an error fetching the profile data!", error);
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchFeedbackMobilePage = async () => {
      try {
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/mobile/page/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        console.log(data);

        // Existing data found, set the first time user state to false
        if (data) {
          setIsFirstTimeUser(false);
        }
        // Set state with fetched data
        setCompanyLogo(
          data.company_logo || "https://via.placeholder.com/100x100"
        );
        setCoverImage(
          data.cover_image || "https://via.placeholder.com/300x200"
        );
        setWelcomeMessage(data.welcome_message || "Hi there 👋");
        setVisitMessage(
          data.visit_message || "Thanks for being our visitor today!"
        );
        setBgColor(data.bg_color || "#FFFFFF");

        // Handle buttons if available
        const fetchedButtons = Object.entries(data.buttons || {}).map(
          ([key, value]) => {
            const label = typeof value === "object" ? value.label : value;
            const isDefault =
              label === "Leave Us A Review"
                ? true
                : typeof value === "object" && value.isDefault !== undefined
                ? value.isDefault
                : false;
            const bgColor =
              typeof value === "object" && value.bgColor !== undefined
                ? value.bgColor
                : "";

            return {
              label,
              visible: true,
              isDefault, // Set isDefault based on the button's label
              bgColor, // Use bgColor instead of bg
            };
          }
        );

        setButtons(fetchedButtons);
      } catch (error) {
        console.error("Error fetching feedback mobile page:", error);
      }
    };

    fetchFeedbackMobilePage();
  }, []);

  // Save Preview Button Handler
  const handleSavePreview = () => {
    if (isUpdating) {
      console.log("Cannot save preview while updating.");
      return; // Prevent save if updating
    }
    createFeedbackMobilePage();
  };

  // Update Preview Button Handler
  const handleUpdatePreview = () => {
    if (isFirstTimeUser) {
      console.log("Cannot update, you need to create a preview first.");
      return; // Prevent update if first-time user
    }
    updateFeedbackMobilePage();
  };

  // Handle Cover Image Change
  const handleCoverImageChange = () => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImage(reader.result);
        setNewCoverImage(""); // Clear the URL input when file is used
      };
      reader.readAsDataURL(file);
    } else if (newCoverImage) {
      setCoverImage(newCoverImage);
      setFile(null); // Clear the file input when URL is used
    }
    setShowModal(false);
  };

  // Update button label and visibility
  // const handleButtonChange = (index, newValue) => {
  //   const newButtons = [...buttons];
  //   newButtons[index].label = newValue;

  //   // Hide button if the label is empty, otherwise show it
  //   newButtons[index].visible = newValue.trim() !== "";
  //   setButtons(newButtons);
  // };
  const handleButtonChange = (index, newValue) => {
    setButtons((prevButtons) => {
      const updatedButtons = [...prevButtons];
      updatedButtons[index] = {
        ...updatedButtons[index],
        label: newValue, // Ensure newValue is a string
      };
      return updatedButtons;
    });
  };

  const toggleButtonVisibility = (index) => {
    const newButtons = [...buttons];
    newButtons[index].visible = !newButtons[index].visible;
    setButtons(newButtons);
  };

  // Function to create a new feedback mobile page
  const createFeedbackMobilePage = async () => {
    const token = localStorage.getItem("token");
    const layoutData = {
      cover_image: coverImage,
      welcome_message: welcomeMessage,
      visit_message: visitMessage,
      bg_color: bgColor,
      buttons: buttons
        .filter((btn) => btn.visible) // Only send visible buttons
        .reduce((acc, btn, index) => {
          acc[`button_${index + 1}`] = {
            label: btn.label,
            bgColor: btn.bgColor, // Send button bgColor
            isDefault: btn.isDefault, // Send button isDefault
          };
          return acc;
        }, {}),
    };

    try {
      const response = await axios.post(
        "https://zola.technology/api/company/feedback/mobile/create-page/",
        layoutData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Feedback mobile page created successfully");
      console.log("Feedback mobile page created successfully:", response.data);
    } catch (error) {
      toast.error("Error creating feedback mobile page:", error);
      console.error("Error creating feedback mobile page:", error);
    }
  };

  const updateFeedbackMobilePage = async () => {
    const token = localStorage.getItem("token");
    const layoutData = {
      company_logo: companyLogo,
      cover_image: coverImage,
      welcome_message: welcomeMessage,
      visit_message: visitMessage,
      bg_color: bgColor,
      buttons: buttons
        .filter((btn) => btn.visible) // Only send visible buttons
        .reduce((acc, btn, index) => {
          acc[`button_${index + 1}`] = {
            label: btn.label,
            bgColor: btn.bgColor, // Send button bgColor
            isDefault: btn.isDefault, // Send button isDefault
          };
          return acc;
        }, {}),
    };

    try {
      const response = await axios.patch(
        "https://zola.technology/api/company/feedback/mobile/update/",
        layoutData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Feedback mobile page updated successfully");
      console.log("Feedback mobile page updated successfully:", response.data);
    } catch (error) {
      toast.error("Error updating feedback mobile page:", error);
      // console.error("Error updating feedback mobile page:", error);
    }
  };

  const predefinedColors = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
    "gray",
    "brown",
    "violet",
    "teal",
  ];

  // Function to capitalize the first letter of each word
  // const capitalizeFirstLetter = (str) => {
  //   return str
  //     .split(" ")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  // Remove duplicates and sort the colors
  // const sortedColors = [
  //   ...new Set(predefinedColors.map((color) => color.toLowerCase())),
  // ] // Create a Set to remove duplicates
  //   .map((color) => capitalizeFirstLetter(color))
  //   .sort();

  // Function to add a new button
  const addNewButton = () => {
    if (newButtonLabel.trim() === "") return; // Prevent adding empty buttons
    const newButton = { label: newButtonLabel, visible: true };
    setButtons((prevButtons) => [...prevButtons, newButton]);
    setNewButtonLabel(""); // Clear the input after adding the button
  };

  // Render the buttons
  const renderButtons = () => {
    return buttons.map((btn, index) => {
      if (!btn.visible) return null; // Only render visible buttons
      return (
        <button
          key={index}
          className={`btn ${buttonColor(bgColor)}`}
          style={{ color: textColor }}
        >
          {btn.label}
        </button>
      );
    });
  };

  console.log(buttons);

  // Update button background color
  const handleButtonColorChange = (index, newColor) => {
    const updatedButtons = [...buttons];
    updatedButtons[index].bgColor = newColor;
    setButtons(updatedButtons);
  };

  const [selectedColor, setSelectedColor] = useState(""); // Track the selected color

  const handleColorClick = (color) => {
    setSelectedColor(color); // Update the selected color
    setBgColor(color); // Update the background color
  };

  return (
    <div className="flex min-h-screen p-6 bg-gray-100 dark:bg-gray-900 transition duration-300">
      {/* Left Side Form */}
      <div className="w-1/2 rounded-lg shadow-lg p-6 mr-4 bg-white dark:bg-gray-700 transition duration-300">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-200">
          Customize Your Mobile Review Page
        </h2>

        {/* Color Picker for Background */}
        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Mobile Frame Background Color
          </label>
          <input
            type="color"
            value={bgColor}
            onChange={(e) => setBgColor(e.target.value)}
            className="w-full h-10 border rounded-md bg-gray-200 dark:bg-gray-600 transition duration-200"
          />
        </div>

        <div className="color-picker-container mb-4">
          <h2 className="text-gray-800 dark:text-gray-200">Select a color:</h2>
          <div className="color-grid">
            {predefinedColors.map((color, index) => (
              <div
                key={index}
                className={`color-swatch ${
                  selectedColor === color ? "selected" : ""
                }`}
                style={{ backgroundColor: color }}
                onClick={() => handleColorClick(color)} // Pass the clicked color
              />
            ))}
          </div>
        </div>

        {/* Company Logo Display (No Input) */}
        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Company Logo
          </label>
          <img
            src={companyLogo}
            alt="Company Logo"
            className="w-24 h-24 rounded-full border-4 border-gray-200 dark:border-gray-600 shadow-lg object-contain"
          />
        </div>

        {/* Cover Picture Upload/URL */}
        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Cover Picture
          </label>
          <button
            onClick={() => setShowModal(true)}
            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition duration-200 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            <FiUpload className="inline mr-2" /> Change Cover Picture
          </button>
        </div>

        {/* Welcome Message Input */}
        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Welcome Message
          </label>
          <input
            type="text"
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            className="w-full px-4 py-2 border rounded-md bg-gray-200 dark:bg-gray-600 dark:text-gray-200 transition duration-200"
          />
        </div>

        {/* Visitor Message Input */}
        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Visitor Message
          </label>
          <input
            type="text"
            value={visitMessage}
            onChange={(e) => setVisitMessage(e.target.value)}
            className="w-full px-4 py-2 border rounded-md bg-gray-200 dark:bg-gray-600 dark:text-gray-200 transition duration-200"
          />
        </div>

        {/* Buttons Customization */}
        <div className="mb-6">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Action Buttons
          </label>
          {buttons.map((button, index) => {
            return (
              <div key={index} className="flex flex-col mb-2">
                <div className="flex items-center">
                  {button.isDefault ? (
                    <span className="font-medium mr-2 text-gray-800 dark:text-gray-200">
                      {button.label}
                    </span>
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        checked={button.visible}
                        onChange={() => toggleButtonVisibility(index)}
                        className="mr-2"
                      />
                      <input
                        type="text"
                        value={button.label}
                        onChange={(e) =>
                          handleButtonChange(index, e.target.value)
                        }
                        className={`w-full px-4 py-2 border rounded-md bg-gray-200 dark:bg-gray-600 dark:text-gray-200 transition duration-200`}
                      />
                    </>
                  )}
                </div>
                <div>
                  {/* Color Picker */}
                  <ColorPicker
                    selectedColor={button.bgColor}
                    onColorClick={(color) =>
                      handleButtonColorChange(index, color)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>

        {/* Input for new button */}
        <div className="flex my-4 w-full">
          <input
            type="text"
            value={newButtonLabel}
            onChange={(e) => setNewButtonLabel(e.target.value)}
            placeholder="New Button Label"
            className="border rounded p-2 w-2/3 bg-gray-200 dark:bg-gray-600 dark:text-gray-200"
          />
          <button
            onClick={addNewButton}
            className="btn bg-blue-500 text-white ml-2 w-1/3 rounded-md"
          >
            Add Button
          </button>
        </div>

        <div className="flex items-center justify-between gap-4">
          <button
            onClick={handleSavePreview}
            disabled={isUpdating} // Disable if updating
            className={`px-4 py-2 ${
              isUpdating ? "bg-gray-400" : "bg-green-500"
            } text-white rounded-md hover:bg-green-600 transition duration-200`}
          >
            Save Preview
          </button>

          <button
            onClick={handleUpdatePreview}
            disabled={isFirstTimeUser} // Disable if first-time user
            className={`bg-green-500 text-white py-2 px-4 rounded ${
              isFirstTimeUser ? "bg-gray-400" : ""
            }`}
          >
            Update Feedback Mobile Page
          </button>
        </div>
      </div>

      {/* Right Side Mobile Preview */}
      <div className="w-1/2 flex justify-center sticky top-[4rem] h-screen">
        {/* Mobile Frame */}
        <div className="relative bg-black h-[600px] w-[300px] rounded-3xl shadow-xl p-4 transition duration-300">
          {/* Notch */}
          <div className="bg-gray-800 w-1/3 h-2 rounded-full mx-auto my-2"></div>
          {/* Screen */}
          <div
            className="rounded-xl relative overflow-hidden h-[95%] transition duration-300"
            style={{ backgroundColor: bgColor }} // Set background color here
          >
            {/* Header Image */}
            <div className="relative">
              <img
                src={coverImage}
                alt="Company Cover"
                className="w-full h-40 object-cover"
              />
              {/* Company Logo in the bottom left */}
              <img
                src={companyLogo}
                alt="Company Logo"
                className="absolute -bottom-6 left-4 w-24 h-24 rounded-full border-4 border-white shadow-lg object-contain"
              />
            </div>

            {/* Welcome Message */}
            <div className="mt-6 text-center px-4" style={{ color: textColor }}>
              <h1 className="text-2xl font-semibold mb-1 text-gray-800 dark:text-gray-200">
                {welcomeMessage}
              </h1>
              <p className="text-lg mb-6 text-gray-600 dark:text-gray-300">
                {visitMessage}
              </p>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col items-center mb-4 px-4">
              {Object.keys(buttons).map((key, index) => {
                const button = buttons[key];
                return (
                  button.visible && (
                    <button
                      key={index}
                      style={{ color: "#fff", backgroundColor: button.bgColor }} // Apply selected background color
                      className={`w-full py-2 mb-2 text-white rounded-md transition duration-200 hover:bg-blue-600`}
                    >
                      {button.label}
                    </button>
                  )
                );
              })}
            </div>

            {/* Footer */}
            <footer className="text-center absolute bottom-2 text-white w-full">
              <p className="text-sm">
                Powered by{" "}
                <span className="font-semibold">Zola Technologies</span>
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobilePreview;
