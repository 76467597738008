import { useState } from 'react';
import {Link} from 'react-router-dom'; 
import IconifyIcon from './IconifyIcon';
import Image from './Image';
import LogoImg from './Logo.png';
import ProfileMenu from './ProfileMenu';

const Topbar = ({ isClosing, mobileOpen, setMobileOpen }) => {
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <div className="flex items-center justify-between my-4 lg:my-2 mr-4">
      <div className="flex items-center space-x-4">
        {/* Mobile menu icon */}
        <div className="block lg:hidden">
          <button
            className="p-2 text-white"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <IconifyIcon icon="mingcute:menu-line" />
          </button>
        </div>

        {/* Logo */}
        <Link href="/">
          <a className="block lg:hidden">
            <Image src={LogoImg} alt="logo" width={96} />
          </a>
        </Link>

        {/* Title */}
        <h1 className="text-2xl font-semibold tracking-wide hidden lg:block">
          ZOLA HQ
        </h1>
      </div>

      <div className="flex items-center space-x-2">
        {/* Notifications */}
          <button className="p-2 text-white">
            <IconifyIcon icon="ion:notifications" />
          </button>

        {/* Profile Menu */}
        <ProfileMenu />
      </div>
    </div>
  );
};

export default Topbar;
