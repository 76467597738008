import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentDetailsForm from './PaymentDetailsForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Load your Stripe publishable key
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const PaymentPage = () => {
  const [loading, setLoading] = useState(false);

  const handlePaymentMethodReceived = async (paymentMethodId) => {
    const token = localStorage.getItem("token");
    
    try {
      setLoading(true);
      const response = await fetch('https://zola.technology/api/company/stripe-payment-details/update/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ payment_method_id: paymentMethodId }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Something went wrong.');
      }
      
      toast.success('Payment method updated successfully!');
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <PaymentDetailsForm onPaymentMethodReceived={handlePaymentMethodReceived} />
      </Elements>
      <ToastContainer />
    </>
  );
};

export default PaymentPage;
