import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Account = () => {
  const [profile, setProfile] = useState({});
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isEditingPreferredBusinessName, setIsEditingPreferredBusinessName] =
    useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://zola.technology/api/company/profile/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
        setEmail(response.data.email || "");
        setUsername(response.data.username || "");
        setPreferredBusinessName(response.data.preferred_business_name || "");
      })
      .catch((error) => {
        console.error("There was an error fetching the profile data!", error);
      });
  }, []);

  const handleEdit = (field) => {
    const token = localStorage.getItem("token");
    let data = {};

    if (field === "email") {
      data.email = email;
    } else if (field === "username") {
      data.username = username;
    } else if (field === "preferred_business_name") {
      data.preferred_business_name = preferredBusinessName; // Add this line
    }

    axios
      .patch("https://zola.technology/api/company/update/", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
        toast.success(
          `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } updated successfully!`
        );
        setIsEditingEmail(false);
        setIsEditingUsername(false);
        setIsEditingPreferredBusinessName(false);
      })
      .catch((error) => {
        console.error(`There was an error updating the ${field}!`, error);
        toast.error(`Failed to update ${field}. Please try again.`);
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check if the file size is greater than 2MB (2 * 1024 * 1024 bytes)
    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size must be less than or equal to 2MB.");
      return;
    }

    const token = localStorage.getItem("token");
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post("https://zola.technology/api/upload-file/", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const imageUrl = response.data.file_url; // Get the URL from the response
        return axios.patch(
          "https://zola.technology/api/company/update/",
          { profile_image: imageUrl },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then((response) => {
        setProfile(response.data);
        toast.success("Image uploaded and profile updated successfully!");
      })
      .catch((error) => {
        console.error("There was an error uploading the image!", error);
        toast.error("Failed to upload image. Please try again.");
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleImageRemove = () => {
    const token = localStorage.getItem("token");

    axios
      .delete(
        `https://zola.technology/api/delete-file/?file_url=${encodeURIComponent(
          profile.profile_image
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        return axios.patch(
          "https://zola.technology/api/company/update/",
          { profile_image: "" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then((response) => {
        setProfile(response.data);
        toast.success("Image removed and profile updated successfully!");
      })
      .catch((error) => {
        console.error("There was an error removing the image!", error);
        toast.error("Failed to remove image. Please try again.");
      });
  };

  const handleHQUpdate = () => {
    const token = localStorage.getItem("token");

    axios
      .patch(
        "https://zola.technology/api/hq-company/update/update-company-to-hq/",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setProfile(response.data);
        toast.success("Company successfully updated to HQ!");
      })
      .catch((error) => {
        console.error("There was an error updating the company to HQ!", error);
        toast.error("Failed to update to HQ. Please try again.");
      });
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-6 text-gray-900 dark:text-gray-100">
        My Profile
      </h2>
      <div className="flex items-center mb-4">
        {profile.profile_image ? (
          <img
            className="w-16 h-16 rounded-full mr-4 object-contain"
            src={profile.profile_image}
            alt={`${profile.first_name} ${profile.last_name}`}
          />
        ) : (
          <div
            className="w-16 h-16 rounded-full mr-4 bg-gray-200 dark:bg-gray-700 flex items-center justify-center cursor-pointer"
            onClick={() => document.getElementById("imageUpload").click()}
          >
            <span className="text-gray-700 dark:text-gray-300">Upload</span>
          </div>
        )}
        <div>
          <input
            type="file"
            id="imageUpload"
            className="hidden"
            accept="image/*"
            onChange={handleImageUpload}
          />
          <button
            onClick={() => document.getElementById("imageUpload").click()}
            className="px-4 py-2 bg-black dark:bg-gray-600 text-white dark:text-gray-300 rounded-md mr-2"
            disabled={isUploading}
          >
            {isUploading
              ? "Uploading..."
              : profile.profile_image
              ? "Change Image"
              : "Upload Image"}
          </button>
          {profile.profile_image && (
            <button
              onClick={handleImageRemove}
              className="px-4 py-2 border border-black dark:border-gray-600 rounded-md text-black dark:text-gray-300"
              disabled={isUploading}
            >
              Remove Image
            </button>
          )}
          <p className="text-gray-600 text-sm mt-2 dark:text-white">
            We support PNGs, JPEGs, and GIFs under 2MB.
          </p>
        </div>
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 mb-2 dark:text-white  ">First Name</label>
        <input
          type="text"
          value={profile.first_name || ""}
          className="w-full px-3 py-2 border rounded-md dark:text-white dark:bg-gray-800"
          readOnly
        />
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 mb-2 dark:text-white ">Last Name</label>
        <input
          type="text"
          value={profile.last_name || ""}
          className="w-full px-3 py-2 border rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
          readOnly
        />
      </div>
      <h3 className="text-xl font-semibold mb-6 text-gray-900 dark:text-gray-100">
        Account Security
      </h3>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-gray-300 mb-2">
          Email
        </label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 border rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
          readOnly={!isEditingEmail}
        />
        {isEditingEmail ? (
          <div className="mt-2">
            <button
              onClick={() => handleEdit("email")}
              className="px-4 py-2 bg-blue-500 dark:bg-blue-700 text-white rounded-md mr-2"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsEditingEmail(false);
                setEmail(profile.email || "");
              }}
              className="px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md"
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditingEmail(true)}
            className="mt-2 px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md"
          >
            Change email
          </button>
        )}
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-gray-300 mb-2">
          Username
        </label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full px-3 py-2 border rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
          readOnly={!isEditingUsername}
        />
        {isEditingUsername ? (
          <div className="mt-2">
            <button
              onClick={() => handleEdit("username")}
              className="px-4 py-2 bg-blue-500 dark:bg-blue-700 text-white rounded-md mr-2"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsEditingUsername(false);
                setUsername(profile.username || "");
              }}
              className="px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md"
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditingUsername(true)}
            className="mt-2 px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md"
          >
            Change username
          </button>
        )}
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-gray-300 mb-2">
          Preferred Business Name
        </label>
        <input
          type="text"
          value={preferredBusinessName}
          onChange={(e) => setPreferredBusinessName(e.target.value)}
          className="w-full px-3 py-2 border rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
          readOnly={!isEditingPreferredBusinessName} // Add a state if you want to toggle editing
        />
        {isEditingPreferredBusinessName ? (
          <div className="mt-2">
            <button
              onClick={() => handleEdit("preferred_business_name")}
              className="px-4 py-2 bg-blue-500 dark:bg-blue-700 text-white rounded-md mr-2"
            >
              Save
            </button>
            <button
              onClick={() => {
                setIsEditingPreferredBusinessName(false);
                setPreferredBusinessName(profile.preferred_business_name || "");
              }}
              className="px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md"
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditingPreferredBusinessName(true)}
            className="mt-2 px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 rounded-md"
          >
            Change Preferred Business Name
          </button>
        )}
      </div>
      {profile.hq_company === null && profile.is_hq_company === false &&(
        <div className="mb-6">
          <button
            onClick={handleHQUpdate}
            className="px-4 py-2 bg-blue-500 dark:bg-blue-700 text-white rounded-md"
          >
            Update to HQ
          </button>
        </div>
      )}
    </div>
  );
};

export default Account;
