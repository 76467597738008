import { useState, useContext } from 'react';
import { Tooltip, Menu, MenuItem, IconButton, Divider } from '@mui/material'; 
import { AuthContext } from "../../../context/AuthContext"; 
import { useNavigate } from 'react-router-dom';  // Import useNavigate for redirection
import IconifyIcon from './IconifyIcon';

const menuItems = [
  { id: 1, title: 'Switch to Branch Account', icon: 'mingcute:user-2-fill' },
  { id: 2, title: 'Logout', icon: 'material-symbols:logout' },
];

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useContext(AuthContext); // Accessing the logout function from AuthContext
  const userData = JSON.parse(localStorage.getItem("userData"));
  const profileImage = userData?.profile_image;
  const firstName = userData?.first_name;
  const lastName = userData?.last_name;
  const email = userData?.email;

  const navigate = useNavigate();  // Initialize useNavigate for navigation

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    // Clear the token and user data from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
  
    // Clear session history
    window.history.pushState(null, null, '/login');
    
    // Redirect to login page
    window.location.href = '/login';
  };
  
  

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchToBranch = () => {
    handleProfileMenuClose();  // Close the menu first
    navigate('/dashboard');    // Navigate to /dashboard
  };

  return (
    <>
      <Tooltip title="Profile">
        <button
          onClick={handleProfileClick}
          className="flex items-center space-x-2 focus:outline-none"
        >
          <div className="flex items-center space-x-2">
            <div className="relative w-8 h-8">
              <img
                src={profileImage}
                alt="Profile Picture"
                className="w-12 h-8 object-cover rounded-full bg-blue-500"
              />
            </div>
            <span className="text-sm font-medium">{firstName}</span>
          </div>
        </button>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleProfileMenuClose}
        PaperProps={{
          style: {
            width: 240,
            marginTop: 8,
            padding: 0,
            overflow: 'hidden',
            background: "#0c111b"
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfileMenuClose} className="flex items-center p-2 hover:bg-blue-100">
          <div className="relative w-9 h-9 mr-2">
            <img
              src={profileImage}
              alt="Profile Picture"
              className="rounded-full bg-blue-500"
            />
          </div>
          <div className="flex flex-col">
            <span className="text-xs font-semibold text-gray-800 dark:text-white">{firstName} {lastName}</span>
            <span className="text-xs text-gray-600 dark:text-white">{email}</span>
          </div>
        </MenuItem>

        <Divider />

        {menuItems.map((item) => (
          <MenuItem 
            key={item.id} 
            onClick={item.title === 'Logout' ? handleLogout : item.title === 'Switch to Branch Account' ? handleSwitchToBranch : handleProfileMenuClose} 
            className="flex items-center py-1 hover:bg-gray-100"
          >
            <div className="mr-2 py-3 text-lg">
              <IconifyIcon className="text-white" icon={item.icon} />
            </div>
            <span className="text-sm text-gray-700 dark:text-white">{item.title}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProfileMenu;
