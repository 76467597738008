import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ButtonBase from '@mui/material/ButtonBase';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import IconifyIcon from '../topbar/IconifyIcon';
import Image from '../topbar/Image';
import LogoImg from '../topbar/Logo.png';
import AvatarImage from '../topbar/avater.png';
import sitemap from './sitemap'; // Import your sitemap file

const Sidebar = ({ mobileOpen, setMobileOpen, setIsClosing }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const profileImage = userData?.profile_image;
  const firstName = userData?.first_name;
  const lastName = userData?.last_name;
  const joinedName = firstName + " " + lastName;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const DrawerItems = () => (
    <Stack
      height="100%"
      justifyContent="space-between"
    >
      <Box>
        {/* Logo and Search */}
        <Stack
          pt={5}
          pb={4}
          px={3.5}
          position={'sticky'}
          top={0}
          bgcolor="#111827"
          alignItems="center"
          justifyContent="flex-start"
          zIndex={1000}
        >
          <ButtonBase component={Link} href="/hqdashboard" disableRipple>
            <Link to="/hqdashboard">
              <Image src={LogoImg} alt="logo" width={144} sx={{ mr: 1, objectFit: 'cover' }} />
            </Link>
          </ButtonBase>
        </Stack>
  
        {/* Search Field */}
        <Box px={3.5} pb={3} pt={1} sx={{ backgroundColor: "#111827" }}>
          <TextField
            variant="filled"
            placeholder="Search for..."
            sx={{ width: 1, input: { color: 'white' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconifyIcon icon={'mingcute:search-line'} sx={{ color: 'white' }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
  
        {/* Top List */}
        <List component="nav" sx={{ px: 2.5, backgroundColor: '#111827', color: 'white' }}>
          {sitemap.map((route, index) => (
            route.items ? <CollapseListItem key={route.id} {...route} /> : <ListItem key={route.id} {...route} />
          ))}
        </List>
      </Box>
  
      {/* Profile List */}
      <Box>
        <Divider sx={{ borderColor: 'white', opacity: 0.2 }} />
        <List component="nav" sx={{ px: 2.5, backgroundColor: '#111827', color: 'white' }}>
          <ProfileListItem subheader="John Carter" path="#!" />
        </List>
      </Box>
    </Stack>
  );
  

  const ProfileListItem = ({ subheader, path }) => (
    <ListItemButton component={Link} href={path}>
      <Stack spacing={1} alignItems="center" sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: '1rem' }}>
        <Avatar src={profileImage} sx={{ height: 36, width: 36, bgcolor: 'primary.main' }} />
        <Stack direction="column">
          <Typography variant="subtitle2" color="white" letterSpacing={0.5}>
            {joinedName}
          </Typography>
          <Typography variant="caption" color="white" fontWeight={400}>
            Account Settings
          </Typography>
        </Stack>
      </Stack>
    </ListItemButton>
  );

  const ListItem = ({ subheader, icon: Icon, path, active }) => {
    const [open, setOpen] = useState(false);
  
    const handleClick = () => {
      setOpen(!open);
    };
  
    return (
      <ListItemButton
        component={Link}
        to={path} // Use "to" prop instead of "href"
        onClick={handleClick}
        sx={{ opacity: active ? 1 : 0.3, color: 'white' }}
      >
        <ListItemIcon>
          <Icon className="text-white" />
        </ListItemIcon>
        <ListItemText
          primary={subheader}
          sx={{
            '& .MuiListItemText-primary': {
              color: active && path === '/' ? 'primary.main' : 'white',
            },
          }}
        />
      </ListItemButton>
    );
  };
  
  const CollapseListItem = ({ subheader, active, items, icon: Icon }) => {
    const [open, setOpen] = useState(false);
  
    const handleClick = () => {
      setOpen(!open);
    };
  
    return (
      <>
        <ListItemButton onClick={handleClick} sx={{ color: 'white' }}>
          <ListItemIcon>
            <Icon className="text-white" />
          </ListItemIcon>
          <ListItemText
            primary={subheader}
            sx={{
              '& .MuiListItemText-primary': {
                color: active ? 'primary.main' : 'white',
              },
            }}
          />
          <IconifyIcon
            icon="iconamoon:arrow-right-2-duotone"
            color="neutral.dark"
            sx={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
              color: 'white',
            }}
          />
        </ListItemButton>
  
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items?.map((route) => (
              <ListItemButton
                key={route.pathName}
                component={Link}
                to={route.path} // Use "to" prop instead of "href"
                sx={{
                  pl: 1.75,
                  borderLeft: 4,
                  borderStyle: 'solid',
                  borderColor: route.active ? 'primary.main' : 'transparent !important',
                  bgcolor: route.active ? 'info.dark' : 'info.darker',
                }}
              >
                <ListItemText
                  primary={route.name}
                  sx={{
                    '& .MuiListItemText-primary': {
                      color: route.active ? 'text.primary' : 'text.secondary',
                    },
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </>
    );
  };
  

  return (
    <Box
      component="nav"
      width={{ lg: 300 }}
      flexShrink={{ lg: 0 }}
      display={{ xs: 'none', lg: 'block' }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        sx={{ 
          display: { xs: 'block', lg: 'none' }, 
          '& .MuiDrawer-paper': { backgroundColor: '#111827', borderRight: 'none' } 
        }}
      >
        <DrawerItems />
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{ 
          display: { xs: 'none', lg: 'block' }, 
          '& .MuiDrawer-paper': { backgroundColor: '#111827', borderRight: 'none' } 
        }}
        open
      >
        <DrawerItems />
      </Drawer>
    </Box>
  );
};

export default Sidebar;
