import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaPhoneAlt, FaRegSmile } from "react-icons/fa";
import { FaNfcDirectional, FaChartLine } from "react-icons/fa6";
import Modal from "./Modal";
import zolaVideo from "./zola.mp4";

function HowItWorks() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div id="how-it-works" className="mx-auto p-4 my-8 font-figtree">
      <motion.h2
        ref={ref}
        className="text-center text-2xl sm:text-3xl md:text-4xl lg:text-[38px] font-semibold mb-6 md:mb-8 leading-snug md:leading-[49px]"
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 50 },
        }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        How It Works
      </motion.h2>

      <motion.div
        className="max-w-[1340px] mx-auto bg-[#eef1fb] p-6 sm:p-8 rounded-lg shadow-lg"
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 50 },
        }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="flex flex-col lg:flex-row items-start gap-8">
          <div className="w-full lg:w-1/2">
            <motion.h3
              className="text-lg sm:text-xl md:text-[29px] leading-snug md:leading-[38px] font-semibold mb-4"
              initial={{ opacity: 0, x: -50 }}
              animate={controls}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 },
              }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Elevate Your Customer Insights
            </motion.h3>
            <motion.ul
              className="list-disc font-normal list-inside pl-5 text-base sm:text-lg md:text-xl leading-6 md:leading-9 space-y-2"
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              <li>Effortlessly capture customer feedback from all your touchpoints</li>
              <li>Build a secure and compliant database from your existing channels</li>
              <li>Engage customers with personalized, data-driven interactions</li>
            </motion.ul>

            <button
              onClick={() => setIsModalOpen(true)}
              className="mt-6 md:mt-8 px-4 py-2 md:px-6 md:py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Watch Video
            </button>
          </div>
          <motion.div
            className="w-full lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8"
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 50 },
            }}
            transition={{ duration: 1, delay: 0.5, ease: "easeOut" }}
          >
            <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 flex items-start gap-3 md:gap-4">
              <div className="text-blue-600 text-2xl md:text-3xl">
                <FaNfcDirectional />
              </div>
              <div>
                <h4 className="text-lg md:text-xl font-semibold mb-1 md:mb-2">1. Smart NFC Cards</h4>
                <p className="text-sm md:text-base">Give customers NFC cards for seamless feedback</p>
              </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 flex items-start gap-3 md:gap-4">
              <div className="text-blue-600 text-2xl md:text-3xl">
                <FaPhoneAlt />
              </div>
              <div>
                <h4 className="text-lg md:text-xl font-semibold mb-1 md:mb-2">2. Tap and Engage</h4>
                <p className="text-sm md:text-base">Customers tap the card with their phone to share thoughts</p>
              </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 flex items-start gap-3 md:gap-4">
              <div className="text-blue-600 text-2xl md:text-3xl">
                <FaRegSmile />
              </div>
              <div>
                <h4 className="text-lg md:text-xl font-semibold mb-1 md:mb-2">3. Real-Time Feedback</h4>
                <p className="text-sm md:text-base">
                  Get feedback instantly and take proactive steps to improve
                </p>
              </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 md:p-6 flex items-start gap-3 md:gap-4">
              <div className="text-blue-600 text-2xl md:text-3xl">
                <FaChartLine />
              </div>
              <div>
                <h4 className="text-lg md:text-xl font-semibold mb-1 md:mb-2">4. Track and Optimize</h4>
                <p className="text-sm md:text-base">
                  Analyze trends to refine strategies and boost satisfaction
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        videoSrc={zolaVideo}
      />
    </div>
  );
}

export default HowItWorks;
