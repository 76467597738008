import React, { useState, useContext, useEffect } from "react";
import { FaSearch, FaMoon, FaSun } from "react-icons/fa";
import { Tooltip, Menu, MenuItem, IconButton, Divider } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";

const Topbar = ({ toggleSidebar, theme, toggleTheme }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser, logout } = useContext(AuthContext);
  const open = Boolean(anchorEl);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const profileImage = userData?.profile_image;
  const firstName = userData?.first_name;
  const lastName = userData?.last_name;
  const email = userData?.email;
  const isHq = userData?.is_hq_company;

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  if (!currentUser) {
    return null;
  }

  return (
    <div className="bg-white dark:bg-gray-900 shadow-md p-4 flex items-center justify-between w-screen md:w-full">
      {/* Search Bar */}
      <div className="flex items-center bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 p-2 rounded-full w-[50%] max-w-md shadow-sm">
        <FaSearch className="text-blue-500 mr-2" />
        <input
          type="text"
          placeholder="Search..."
          className="bg-transparent outline-none flex-grow text-gray-700 dark:text-gray-300 placeholder-gray-400 dark:placeholder-gray-500"
        />
        <div className="ml-2 text-gray-400 text-xs hidden md:block">⌘F</div>
      </div>

      {/* User Profile & Menu */}
      <div className="flex items-center space-x-4">
        {/* Theme Toggle Button */}
        <button
          type="button"
          className={`flex items-center justify-center p-2 text-${
            theme === "light" ? "black" : "white"
          } hover:bg-${
            theme === "light" ? "gray-200" : "gray-600"
          } focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${
            theme === "light" ? "black" : "white"
          }`}
          onClick={toggleTheme}
        >
          {theme === "light" ? (
            <FaMoon className="h-6 w-6" />
          ) : (
            <FaSun className="h-6 w-6" />
          )}
        </button>

        {/* Sidebar Toggle Button */}
        <button
          type="button"
          className="md:hidden flex items-center justify-center p-2 text-black dark:text-white hover:bg-gray-600 focus:outline-none"
          aria-expanded="false"
          onClick={toggleSidebar}
        >
          <MdMenu className="h-6 w-6" />
        </button>

        {/* User Profile Button */}
        <Tooltip title="Profile">
          <button
            onClick={handleProfileClick}
            className="flex items-center focus:outline-none"
          >
            <div className="flex items-center">
              <div className="relative w-8 h-8">
                <img
                  src={profileImage}
                  alt="Profile Picture"
                  className="w-12 h-8 rounded-full bg-blue-500 object-cover"
                />
              </div>
              {/* <span className="text-sm font-medium dark:text-white">{firstName}</span> */}
            </div>
          </button>
        </Tooltip>

        {/* Dropdown Menu */}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleProfileMenuClose}
          PaperProps={{
            style: {
              width: 260,
              marginTop: 8,
              padding: 0,
              overflow: "hidden",
              borderRadius: "12px", // Smooth rounded edges
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)", // Softer shadow for depth
              background: "#0c111b"
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* Profile Info */}
          <MenuItem
            onClick={handleProfileMenuClose}
            className="flex items-center p-3 hover:bg-blue-50 transition-all"
          >
            <div className="relative rounded-full mr-3">
              <img
                src={profileImage}
                alt="Profile Picture"
                className="w-12 h-12 rounded-full bg-blue-500 object-cover"
              />
            </div>
            <div className="flex flex-col">
              <span className="text-xs font-semibold text-gray-800 dark:text-white">
                {firstName} {lastName}
              </span>
              <span className="text-xs text-gray-600 dark:text-gray-400">
                {email}
              </span>
            </div>
          </MenuItem>
          <Divider />

          {/* Switch Dashboard Link (conditional) */}
          {isHq && (
            <MenuItem
              onClick={handleProfileMenuClose}
              className="py-3 hover:bg-blue-50 transition-all"
            >
              <Link
                to="/hqdashboard"
                className="block w-full text-sm text-gray-700 dark:text-gray-300 px-4 py-2"
              >
                Switch to HQ Dashboard
              </Link>
            </MenuItem>
          )}

          {/* Logout */}
          <MenuItem
            onClick={handleLogout}
            className="py-3 hover:bg-red-100 transition-all"
          >
            <Link
              to="/"
              className="block w-full text-sm text-gray-700 dark:text-gray-300 px-4 py-2"
            >
              Logout
            </Link>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Topbar;
