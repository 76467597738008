// BranchTable.jsx
import React from 'react';

const BranchTable = ({ branches }) => {
  return (
    <div className="overflow-x-auto rounded-lg shadow-md mt-12">
      <table className="min-w-full bg-[#374151] text-white">
        <thead className="bg-[#4B5563]">
          <tr>
            <th className="py-3 px-6 text-left font-medium">Branch Name</th>
            <th className="py-3 px-6 text-left font-medium">Employees</th>
            <th className="py-3 px-6 text-left font-medium">Issues</th>
            <th className="py-3 px-6 text-left font-medium">Performance</th>
          </tr>
        </thead>
        <tbody>
          {branches.map((branch, index) => (
            <tr
              key={branch.id}
              className={`${
                index % 2 === 0 ? 'bg-[#333b47]' : 'bg-[#4B5563]'
              } hover:bg-[#3B434D] transition-colors duration-200`}
            >
              <td className="py-3 px-6">{branch.name}</td>
              <td className="py-3 px-6">{branch.employees}</td>
              <td className="py-3 px-6">{branch.issues}</td>
              <td className="py-3 px-6">
                <div className="relative w-full bg-[#2D323E] rounded-full h-4">
                  <div
                    className={`absolute top-0 left-0 h-full rounded-full ${
                      parseInt(branch.performance) > 75
                        ? 'bg-green-500'
                        : parseInt(branch.performance) > 50
                        ? 'bg-yellow-500'
                        : 'bg-red-500'
                    }`}
                    style={{ width: branch.performance }}
                  ></div>
                </div>
                <span className="text-sm ml-2">{branch.performance}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BranchTable;
