import { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

const cardStyle = {
  base: {
    color: "#32325d",
    fontFamily: "Arial, sans-serif",
    fontSize: "20px",
    "::placeholder": {
      color: "#a0aec0",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    borderColor: "#fa755a",
  },
};

const PaymentDetailsForm = ({ onPaymentMethodReceived }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [zipCode, setZipCode] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    if (!stripe || !elements) {
      setError("Please try again later.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: {
          postal_code: zipCode,
        },
      },
    });

    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
      onPaymentMethodReceived(paymentMethod.id);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4">
      <div className="flex flex-col gap-4">
        <label className="flex flex-col gap-2 dark:text-gray-300">
          Card Number
          <div className="border border-gray-300 rounded-md p-2 dark:text-gray-300 dark:bg-gray-700">
            <CardNumberElement options={{ style: cardStyle }} />
          </div>
        </label>
        <div className="flex gap-4">
          <div className="flex-1">
            <label className="flex flex-col gap-2 dark:text-gray-300">
              Expiry Date
              <div className="border border-gray-300 rounded-md p-2">
                <CardExpiryElement options={{ style: cardStyle }} />
              </div>
            </label>
          </div>
          <div className="flex-1">
            <label className="flex flex-col gap-2 dark:text-gray-300">
              CVC
              <div className="border border-gray-300 rounded-md p-2">
                <CardCvcElement options={{ style: cardStyle }} />
              </div>
            </label>
          </div>
        </div>
        <label className="flex flex-col gap-2 dark:text-gray-300">
          Zip Code (if required)
          <input
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            placeholder="Enter your zip code"
            className="border border-gray-300 rounded-md p-2 text-lg w-full"
          />
        </label>
      </div>
      {error && <div className="text-red-500 mt-2">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || loading}
        className={`bg-blue-500 text-white py-2 px-4 mt-3 rounded-md hover:bg-blue-600 disabled:bg-gray-400 transition-colors ${
          loading ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        {loading ? "Processing..." : "Submit Payment Details"}
      </button>
    </form>
  );
};

PaymentDetailsForm.propTypes = {
  onPaymentMethodReceived: PropTypes.func.isRequired,
};

export default PaymentDetailsForm;
