import { MdHome, MdSettings } from 'react-icons/md';
import { FaUser, FaCodeBranch } from 'react-icons/fa';
import { DiGoogleAnalytics } from "react-icons/di";
import { GoChecklist } from "react-icons/go";


const sitemap = [
  {
    id: 'dashboard',
    subheader: 'Dashboard',
    path: '/hqdashboard',
    icon: MdHome,
    active: true,
  },
  {
    id: 'analytics',
    subheader: 'Analytics',
    path: '/hqdashboard/analytics',
    icon: DiGoogleAnalytics ,
  },
  {
    id: 'branches',
    subheader: 'Branches',
    path: '/hqdashboard/branches',
    icon: FaCodeBranch,
  },
  {
    id: 'survey',
    subheader: 'Survey',
    path: '/hqdashboard/survey',
    icon: GoChecklist,
  }
];

export default sitemap;

