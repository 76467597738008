import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TotalRevenue = () => {
  const [filter, setFilter] = useState('month');
  const [data, setData] = useState([]);
  const [totalReward, setTotalReward] = useState(0);
  const [currency, setCurrency] = useState('');
  const [maxRewardScore, setMaxRewardScore] = useState(10); // Default max for x-axis
  const [topEmployee, setTopEmployee] = useState(null); // Track employee with the highest reward score

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/rewards/?filter=${filter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = await response.json();
        const actualData = result.data;

        setData(actualData);
        calculateTotalReward(actualData);
        determineTopEmployee(actualData);
        setMaxRewardScore(Math.max(...actualData.map(item => item.reward_score), 10));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [filter]);

  const calculateTotalReward = (data) => {
    const total = data.reduce((acc, item) => acc + item.reward_total, 0);
    const rewardCurrency = data.length > 0 ? data[0].reward_currency : '';
    setTotalReward(total);
    setCurrency(rewardCurrency);
  };

  const determineTopEmployee = (data) => {
    if (data.length > 0) {
      const top = data.reduce((prev, current) =>
        prev.reward_score > current.reward_score ? prev : current, data[0]
      );
      setTopEmployee(top);
    }
  };

  const dynamicBars = () => {
    const periods = filter === 'year' ? 12 : 4; // 12 months or 4 weeks
    return Array.from({ length: periods }).map((_, i) => {
      const rewardScore = data[i] ? data[i].reward_score : 0;
      const shade = rewardScore > 0 ? rewardScore / maxRewardScore : 0.2; // Lighter shade for no score
      return { backgroundColor: `rgba(0, 0, 0, ${shade})` };
    });
  };

  const lineChartData = {
    labels: data.map(item => item.period),
    datasets: [
      {
        label: 'Revenue',
        data: data.map(item => item.reward_total),
        borderColor: 'black',
        borderWidth: 2,
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, 'rgba(0, 0, 0, 0.5)');
          gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
          return gradient;
        },
      },
    ],
  };

  const barChartData = {
    labels: data.map(item => item.employee.username),
    datasets: [
      {
        label: 'Reward Score',
        data: data.map(item => item.reward_score),
        backgroundColor: 'black',
      },
      {
        label: 'Target',
        data: Array(data.length).fill(maxRewardScore),
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    ],
  };

  const chartOptions = {
    indexAxis: 'y',
    elements: { bar: { borderWidth: 2 } },
    responsive: true,
    scales: {
      x: { beginAtZero: true, max: maxRewardScore },
      y: { grid: { display: false } },
    },
    plugins: { legend: { display: false } },
  };

  return (
    <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {/* Total Revenue Card */}
      <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-md">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">Total Incentive</h2>
            <p className="text-2xl text-gray-900 dark:text-white">
              {currency} {totalReward.toLocaleString()}
            </p>
            <p className="text-green-500">12% <span className="text-gray-500 dark:text-gray-400">increase</span></p>
          </div>
          <div>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="border rounded p-1 dark:bg-gray-700 dark:text-white"
            >
              <option value="year">Year</option>
              <option value="month">Month</option>
            </select>
          </div>
        </div>
        <Line data={lineChartData} />
      </div>

      {/* Incentive Breakdown */}
      <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-md">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">Incentive</h2>
            <p className="text-2xl text-gray-900 dark:text-white">
              {currency} {totalReward.toLocaleString()}
            </p>
            <p className="text-green-500">12% <span className="text-gray-500 dark:text-gray-400">increase</span></p>
          </div>
          <div>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="border rounded p-1 dark:bg-gray-700 dark:text-white"
            >
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>
        <Bar data={barChartData} options={chartOptions} />
      </div>

      {/* Top Employee Card */}
      {topEmployee && (
        <div className="bg-white dark:bg-gray-700 p-4 rounded shadow-md">
          <div className="flex flex-col items-center mb-4">
            <img
              src="https://via.placeholder.com/50"
              alt="Profile"
              className="rounded-full w-16 h-16 mb-2"
            />
            <div className="text-center">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white">{topEmployee.employee.username}</h2>
              <p className="text-sm text-gray-500 dark:text-gray-400">{topEmployee.employee.position || 'Employee'}</p>
            </div>
          </div>
          <div className="text-center mb-4">
            <p className="text-3xl font-bold text-gray-900 dark:text-white">
              {currency} {topEmployee.reward_total.toLocaleString()}
            </p>
            <p className="text-gray-500 dark:text-gray-400">Reward Amount for this period</p>
          </div>
          <div className="flex justify-around items-end h-16">
            {dynamicBars().map((bar, index) => (
              <div
                key={index}
                className="w-4 h-full"
                style={{ backgroundColor: bar.backgroundColor }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalRevenue;
