import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import zolaDark from "../assets/Zola.png";
import zolaLight from "../assets/Zola-Dark.png";
import { CiStar } from "react-icons/ci";
import { MdOutlineSpaceDashboard, MdOutlineFeedback, MdOutlineMobileFriendly } from "react-icons/md";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { GrGroup } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { GiCash } from "react-icons/gi";
import { FaHandsHelping } from "react-icons/fa"
import { MdCreateNewFolder } from "react-icons/md";
import { AiOutlineBranches } from "react-icons/ai";

const Sidebar = ({ theme, toggleSidebar }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path
      ? "bg-black dark:bg-blue-700 text-white"
      : theme === "light"
      ? "text-gray-900 hover:bg-gray-200"
      : "text-white hover:bg-gray-600";
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      toggleSidebar();
    }
  };

  const userData = localStorage.getItem("userData");
  const superUser = JSON.parse(userData);
  console.log(superUser.is_superuser);
  const isSuperUser = superUser.is_superuser;
  const isHq = superUser.is_hq_company;
  return (
    <div
      className={`fixed inset-y-0 transition-width duration-300 shadow-lg ${
        theme === "light" ? "bg-white text-gray-900" : "bg-gray-900 text-white"
      } ${isSidebarOpen ? "w-64" : "w-20"}`}
    >
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center">
          <img
            src={theme === "light" ? zolaLight : zolaDark}
            alt="site brand logo"
            className={`${isSidebarOpen ? "w-64" : "w-12"} object-cover h-12`}
          />
        </div>
      </div>
      <div className="flex flex-col flex-grow mt-4">
        <nav className="flex flex-col">
          <ul className="space-y-2">
            <li>
              <Link
                to="/dashboard"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard"
                )}`}
                onClick={handleLinkClick}
              >
                <MdOutlineSpaceDashboard className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Dashboard
                </span>
              </Link>
            </li>
            {/* Uncomment as needed */}
            {/* <li>
              <Link
                to="/dashboard/reviews"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/reviews"
                )}`}
                onClick={handleLinkClick}
              >
                <CiStar className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Reviews
                </span>
              </Link>
            </li> */}
            <li>
              <Link
                to="/dashboard/feedback"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/feedback"
                )}`}
                onClick={handleLinkClick}
              >
                <MdOutlineFeedback className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Feedback
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/analytics"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/analytics"
                )}`}
                onClick={handleLinkClick}
              >
                <BsGraphUpArrow className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Analytics
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/team-performance"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/team-performance"
                )}`}
                onClick={handleLinkClick}
              >
                <GrGroup className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Team Performance
                </span>
              </Link>
            </li>

            <li>
              <Link
                to="/dashboard/reward"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/reward"
                )}`}
                onClick={handleLinkClick}
              >
                <GiCash className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Reward
                </span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/dashboard/mobile-preview"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/mobile-preview"
                )}`}
                onClick={handleLinkClick}
              >
                <MdOutlineMobileFriendly className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Mobile Preview
                </span>
              </Link>
            </li> */}
            <hr />
            {/* Uncomment as needed */}
            <li>
              <Link
                to="/dashboard/members"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/members"
                )}`}
                onClick={handleLinkClick}
              >
                <MdOutlineFormatListBulleted className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Employees
                </span>
              </Link>
            </li>
            {isSuperUser ? (
              <li>
                <Link
                  to="/dashboard/create-account"
                  className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                    "/dashboard/setting"
                  )}`}
                  onClick={handleLinkClick}
                >
                  <MdCreateNewFolder className="w-5 h-5" />
                  <span
                    className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}
                  >
                    Create New Company
                  </span>
                </Link>
              </li>
            ) : null}
            {isHq ? (
              <li>
                <Link
                  to="/dashboard/branch"
                  className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                    "/dashboard/setting"
                  )}`}
                  onClick={handleLinkClick}
                >
                  <AiOutlineBranches className="w-5 h-5" />
                  <span
                    className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}
                  >
                    branches{" "}
                  </span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                to="/dashboard/setting"
                className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${isActive(
                  "/dashboard/setting"
                )}`}
                onClick={handleLinkClick}
              >
                <FiSettings className="w-5 h-5" />
                <span className={`ml-4 ${isSidebarOpen ? "block" : "hidden"}`}>
                  Settings
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;