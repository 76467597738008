import { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  // Global variable to store the interval ID
  let intervalId;

  // Function to refresh the token
  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    try {
      const response = await fetch("https://zola.technology/api/token/refresh/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: refreshToken,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        // Update the new token in localStorage
        localStorage.setItem("token", data.access);

        // Reset expiry time to 24 hours from now
        const newExpiryTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        localStorage.setItem("expiryTime", newExpiryTime);

        console.log(`Token refreshed successfully at ${new Date()}. New expiry time: ${new Date(newExpiryTime)}`);
      } else {
        console.error("Failed to refresh token:", data);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  };

  // Function to calculate how long until the token expires
  const checkTokenExpiry = () => {
    const expiryTime = localStorage.getItem("expiryTime");
    const currentTime = new Date().getTime();

    // If the token has less than 10 minutes remaining, refresh it
    if (expiryTime && currentTime >= expiryTime - 10 * 60 * 1000) { // 10 minutes in milliseconds
      console.log("Token is about to expire. Refreshing...");
      refreshToken();
    } else {
      console.log(`Token is still valid. Expires at: ${new Date(parseInt(expiryTime))}`);
    }
  };

  // Function to check if tokens exist in localStorage and start checking expiry
  const checkTokensAndStartCountdown = () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (token && refreshToken) {
      checkTokenExpiry(); // Check if token is close to expiring
    } else {
      console.log("No tokens found in localStorage. Countdown will not start.");
    }
  };

  // Function to handle login
  const login = ({ username, token, firstName, rtoken }) => {
    setCurrentUser({ username, token, firstName });
    const loginTime = new Date().getTime(); // Store the current time as login time
    const expiryTime = loginTime + 24 * 60 * 60 * 1000; // Expiry time 24 hours from login

    // Store login information and token expiry time in localStorage
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", rtoken);
    localStorage.setItem("loginTime", loginTime);
    localStorage.setItem("expiryTime", expiryTime);

    console.log(`User logged in at ${new Date(loginTime)}. Token expires at ${new Date(expiryTime)}`);

    // Start checking token expiry
    checkTokenExpiry();
  };

  // Function to handle logout
  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loginTime");
    localStorage.removeItem("expiryTime");
    clearInterval(intervalId);
    console.log("User logged out. Midnight refresh stopped.");
  };

  // Check for tokens on load and start checking expiry if necessary
  checkTokensAndStartCountdown();

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
