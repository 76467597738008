// BranchContext.js
import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const BranchContext = createContext();

// Context provider component
export const BranchProvider = ({ children }) => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeTaps, setEmployeeTaps] = useState([]);
  const [tapsLoading, setTapsLoading] = useState(false);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(
          'https://zola.technology/api/hq-company/list/paginated-branch-companies/', 
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log(response)
        
        if (response.ok) {
          const data = await response.json();
          console.log(data)
          const formattedBranches = data.data.map(branch => ({
            id: branch.id,
            name: branch.company_name,
            employees: branch.number_of_employees,
            status: branch.subscription.status ? 'Active' : 'Pending',
            recentActivity: 'No recent activity', // Placeholder
            issues: 0, // Placeholder for issues
            performance: 0, // Placeholder for performance
            upcomingEvents: 'No upcoming events' // Placeholder
          }));
          setBranches(formattedBranches);
        } else {
          console.error('Failed to fetch branches');
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBranches();
  }, []);

   // Fetch Employee Taps for HQ Branches based on filters
   useEffect(() => {
     const fetchEmployeeTaps = async (filter = 'month', page = 1, page_size = 10) => {
      setTapsLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(
          `https://zola.technology/api/hq-company/analytics/get-branch-employee-taps/?filter=${filter}&page=${page}&page_size=${page_size}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.ok) {
          const data = await response.json();
          console.log(data)
          setEmployeeTaps(data);
        } else {
          console.error('Failed to fetch employee taps');
        }
      } catch (error) {
        console.error('Error fetching employee taps:', error);
      } finally {
        setTapsLoading(false);
      }
    };
    fetchEmployeeTaps()
   }, [])

  return (
    <BranchContext.Provider value={{ branches, loading, employeeTaps, tapsLoading }}>
      {children}
    </BranchContext.Provider>
  );
};
