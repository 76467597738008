import PropTypes from 'prop-types';
import '../stylings/header.css'

const DashboardHeader = ({ title }) => {
  return <h1 className="text-3xl font-bold dark:text-white text-gray-800 mb-4">{title}</h1>;
};

DashboardHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default DashboardHeader