import { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../context/AuthContext';

const FeedbackChart = () => {
  const [loading, setLoading] = useState(true);
  const [weeklyData, setWeeklyData] = useState(new Array(7).fill(0)); // Array to hold feedback counts for each day
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser?.token) {
      setLoading(false);
      return;
    }

    // Fetch feedbacks with weekly filter
    const fetchFeedbacks = async () => {
      try {
        const token = currentUser.token;
        const response = await fetch(
          'https://zola.technology/api/company/feedback/get-filtered-feedback/?filter=week', 
          {
            method: 'GET',
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${token}` // Assuming Bearer token auth
            }
          }
        );
        
        const data = await response.json();
        console.log(data);

        if (!Array.isArray(data)) {
          throw new Error("Invalid data format: data is not an array");
        }

        // Use feedback_count directly from API response
        const feedbackCounts = data.map(day => day.feedback_count || 0);
        setWeeklyData(feedbackCounts);

      } catch (error) {
        console.error("Error fetching feedbacks:", error);
        setWeeklyData(new Array(7).fill(0)); // Reset to no feedbacks
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, [currentUser?.token]);

  return (
    <div className="font-sans p-4 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-50 dark:bg-gray-800 shadow-lg">
      <h3 className="text-[24px] text-center font-normal text-gray-800 dark:text-white">
        Feedbacks This Week
      </h3>
      <div className="flex justify-around w-full max-w-xl mx-auto">
        {loading ? (
          <section className="flex justify-center items-center h-full">
            <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
          </section>
        ) : weeklyData.every(count => count === 0) ? (
          <div className="text-center text-gray-800 dark:text-gray-200 w-full">
            No feedbacks found for this week.
          </div>
        ) : (
          weeklyData.map((feedbackCount, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="relative flex flex-col-reverse w-5 h-72 bg-gray-200 dark:bg-gray-700 rounded-lg">
                <div
                  className={`w-full rounded-lg ${feedbackCount > 0 ? 'bg-blue-500' : 'bg-gray-400'}`} // Blue if feedback exists, gray otherwise
                  style={{ height: `${feedbackCount * 30}px` }} // Scale height based on feedback count
                ></div>
              </div>
              <div className="mt-2 text-base font-light text-gray-800 dark:text-gray-200">
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][index]} {/* Day of the week */}
              </div>
              <div className="mt-2 text-lg font-bold text-gray-800 dark:text-white">
                {feedbackCount} {/* Show feedback count for the day */}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FeedbackChart;
