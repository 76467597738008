import React, { useEffect, useState } from 'react';

const SubscriptionComponent = () => {
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [currency, setCurrency] = useState('USD'); // Default to USD
    const [interval, setInterval] = useState(''); // Subscription interval: monthly or yearly

    useEffect(() => {
        // Retrieve user data from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.subscription) {
            setSubscriptionData(userData.subscription);

            // Check if phone_number starts with '254' for KES currency
            if (userData.phone_number.startsWith('254')) {
                setCurrency('KES');
                // setInterval(userData.subscription.subscription_interval)

            }
            setInterval(userData.subscription.subscription_interval)
        }
    }, []);

    if (!subscriptionData) {
        return <p>Loading subscription data...</p>;
    }

    const { subscription_amount, status } = subscriptionData;

    const amount = subscription_amount || 0
    // Format amount according to currency
    const formattedAmount = currency === 'KES' 
        ? `KSh ${parseFloat(amount).toFixed(2)}` 
        : `$${parseFloat(amount).toFixed(2)}`;

    return (
        <div className="p-4 max-w  mx-auto bg-white rounded-lg dark:bg-gray-700 shadow-md">
            <h2 className="text-lg font-bold mb-4 dark:text-gray-300">Subscription Details</h2>
            <form>
                <div className="mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 dark:bg-gray-700">Subscription Amount ({currency}):</label>
                    <input
                        type="text"
                        value={formattedAmount}
                        readOnly
                        className="mt-1 p-2 border border-gray-300 rounded w-full dark:bg-gray-700 dark:text-gray-300"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 dark:text-gray-300">Subscription Interval:</label>
                    <input
                        type="text"
                        value={interval}
                        readOnly
                        className="mt-1 p-2 border border-gray-300 rounded w-full dark:bg-gray-700 dark:text-gray-300"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 dark:text-gray-300">Subscription Status:</label>
                    <input
                        type="text"
                        value={status ? "Active" : "Inactive"}
                        readOnly
                        className="mt-1 p-2 border border-gray-300 rounded w-full dark:bg-gray-700 dark:text-gray-300"
                    />
                </div>
            </form>
        </div>
    );
};

export default SubscriptionComponent;
