import { useState } from "react";
import { Link } from "react-router-dom";
import bulb from "./light-bulb.png";
import "./Pricing.css";

function Pricing() {
  const [isMonthly, setIsMonthly] = useState(true);

  // const togglePricing = () => {
  //   setIsMonthly(!isMonthly);
  // };
  return (
    <section className="pricing-section">
      <h1>Invest in your company&apos;s success</h1>
      <p>
        Business with over 400 reviews more than double the revenue of an
        average business.
      </p>

      <div className="pricing-cards">
        <div className="toggle-pricing">
          <button
            className={isMonthly ? "active" : ""}
            onClick={() => setIsMonthly(true)}
          >
            Pay Monthly
          </button>
          <button
            className={!isMonthly ? "active" : ""}
            onClick={() => setIsMonthly(false)}
          >
            Pay Annually
          </button>
        </div>
        {isMonthly && (
          <div className="monthly-pricing">
            <div className="pricing-card">
              <div className="pricing-card_header">
                <h3>Starter</h3>
                <h2>$49</h2>
                <p>Per month billed monthly</p>
                <hr />
              </div>
              <div className="pricing-card_body">
                <p>250 Review Requests Per Month</p>
                <hr />
                <p>Email Requests</p>
                <hr />
                <p>1 Location</p>
                <hr />
                <p>Direct Link & Reactions</p>
                <hr />
                <p>1 Campaign</p>
                <hr />
                <p>Embed Reviews</p>
                <hr />
                <p className="striked-text">Brand Customization</p>
                <hr />
                <p className="striked-text">Team Management & Permissions</p>
                <hr />
                <Link>View all features</Link>
              </div>
              <div className="pricing-card_footer">
                <Link>Start free 14-day trial</Link>
              </div>
            </div>
            <div className="pricing-card recommended">
              <div className="recommended-header">
                <h4>Recommended</h4>
              </div>
              <div className="pricing-card_header">
                <h3>Small Business</h3>
                <h2>$99</h2>
                <p>Per month billed monthly</p>
                <hr />
              </div>
              <div className="pricing-card_body">
                <p>250 Review Requests Per Month</p>
                <hr />
                <p>Email Requests</p>
                <hr />
                <p>1 Location</p>
                <hr />
                <p>Direct Link & Reactions</p>
                <hr />
                <p>1 Campaign</p>
                <hr />
                <p>Embed Reviews</p>
                <hr />
                <p>Brand Customization</p>
                <hr />
                <p className="striked-text">Team Management & Permissions</p>
                <hr />
                <a href="" className="pricing-card_body__link">
                  View all features
                </a>
              </div>
              <div className="pricing-card_footer">
                <a href="">Start free 14-day trial</a>
              </div>
            </div>
            <div className="pricing-card">
              <div className="pricing-card_header">
                <h3>Premium</h3>
                <h2>$299</h2>
                <p>Per month billed monthly</p>
                <hr />
              </div>
              <div className="pricing-card_body">
                <p>250 Review Requests Per Month</p>
                <hr />
                <p>Email Requests</p>
                <hr />
                <p>1 Location</p>
                <hr />
                <p>Direct Link & Reactions</p>
                <hr />
                <p>1 Campaign</p>
                <hr />
                <p>Embed Reviews</p>
                <hr />
                <p>Brand Customization</p>
                <hr />
                <p>Team Management & Permissions</p>
                <hr />
                <Link>View all features</Link>
              </div>
              <div className="pricing-card_footer">
                <Link>Start free 14-day trial</Link>
              </div>
            </div>
          </div>
        )}
        {!isMonthly && (
          <div className="yearly-pricing">
            <div className="pricing-card">
              <div className="pricing-card_header">
                <h3>Starter</h3>
                <h2>
                  $29 <span>$49</span>
                </h2>
                <p>Per year billed annually</p>
                <hr />
              </div>
              <div className="pricing-card_body">
                <p>250 Review Requests Per Month</p>
                <hr />
                <p>Email Requests</p>
                <hr />
                <p>1 Location</p>
                <hr />
                <p>Direct Link & Reactions</p>
                <hr />
                <p>1 Campaign</p>
                <hr />
                <p>Embed Reviews</p>
                <hr />
                <p className="striked-text">Brand Customization</p>
                <hr />
                <p className="striked-text">Team Management & Permissions</p>
                <hr />
                <Link>View all features</Link>
              </div>
              <div className="pricing-card_footer">
                <Link>Start free 14-day trial</Link>
              </div>
            </div>
            <div className="pricing-card recommended">
              <div className="recommended-header">
                <h4>Recommended</h4>
              </div>
              <div className="pricing-card_header">
                <h3>Small Business</h3>
                <h2>
                  $59 <span>$99</span>
                </h2>
                <p>Per year billed annually</p>
                <hr />
              </div>
              <div className="pricing-card_body">
                <p>250 Review Requests Per Month</p>
                <hr />
                <p>Email Requests</p>
                <hr />
                <p>1 Location</p>
                <hr />
                <p>Direct Link & Reactions</p>
                <hr />
                <p>1 Campaign</p>
                <hr />
                <p>Embed Reviews</p>
                <hr />
                <p>Brand Customization</p>
                <hr />
                <p className="striked-text">Team Management & Permissions</p>
                <hr />
                <a href="" className="pricing-card_body__link">
                  View all features
                </a>
              </div>
              <div className="pricing-card_footer">
                <a href="">Start free 14-day trial</a>
              </div>
            </div>
            <div className="pricing-card">
              <div className="pricing-card_header">
                <h3>Premium</h3>
                <h2>
                  $179 <span>$299</span>
                </h2>
                <p>Per year billed annually</p>
                <hr />
              </div>
              <div className="pricing-card_body">
                <p>250 Review Requests Per Month</p>
                <hr />
                <p>Email Requests</p>
                <hr />
                <p>1 Location</p>
                <hr />
                <p>Direct Link & Reactions</p>
                <hr />
                <p>1 Campaign</p>
                <hr />
                <p>Embed Reviews</p>
                <hr />
                <p>Brand Customization</p>
                <hr />
                <p>Team Management & Permissions</p>
                <hr />
                <Link>View all features</Link>
              </div>
              <div className="pricing-card_footer">
                <Link>Start free 14-day trial</Link>
              </div>
            </div>
          </div>
        )}

        <p
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          <img style={{ width: "16px", height: "16px" }} src={bulb} alt="" />{" "}
          Not seeing a plan that fits your needs?{" "}
          <Link>Talk to our sales.</Link>
        </p>
      </div>

      <div className="appreciation-card">
        <img src={bulb} alt="" />
        <div className="appreciation-content">
          <h2>&quot;We made a great choice.</h2>
          <p>
            Was struggling to get review requests to customers and manage
            responses. TrueReview has helped us immensely. Their dashboard and
            functions are easy to use and understand. The chat staff is right on
            time with answers to questions and they are familiar with their
            product unlike many online chat reps.&quot;
          </p>
          <p>
            WE CLEAN CARPET, <span>Carpet cleaning service</span>
          </p>
          <button>Verified review from G2</button>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
