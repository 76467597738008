const predefinedColors = ["#ff5733", "#33ff57", "#3357ff", "#ff33aa", "#ffea33", "#8e44ad", "#2ecc71", "#e74c3c"];

const ColorPicker = ({ selectedColor, onColorClick }) => (
  <div className="color-picker-container">
    <h2>Select a color:</h2>
    <div className="color-grid">
      {predefinedColors.map((color, index) => (
        <div
          key={index}
          className={`color-swatch ${selectedColor === color ? "selected" : ""}`}
          style={{ backgroundColor: color }}
          onClick={() => onColorClick(color)} // Trigger the parent component to update the button's color
        />
      ))}
    </div>
  </div>
);

// Add some basic CSS to highlight the selected color
// .color-grid {
//   display: grid;
//   grid-template-columns: repeat(4, 40px);
//   gap: 10px;
// }
// .color-swatch {
//   width: 40px;
//   height: 40px;
//   cursor: pointer;
// }
// .color-swatch.selected {
//   border: 3px solid #000;
// }


export default ColorPicker