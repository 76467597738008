import React, { useContext, useState } from 'react';
import { BranchContext } from '../context/BranchContext';
import { FaUsers, FaTasks, FaRegCalendarAlt, FaExclamationTriangle, FaBuilding } from 'react-icons/fa';
import CreateBranchModal from './CreateBranchModal';

function Branches() {
  const { branches } = useContext(BranchContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (formData) => {
    // Handle form submission logic
    console.log(formData);
    // You can make API calls here to submit the form data
  };

  const filteredBranches = branches.filter((branch) =>
    branch.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="grid grid-cols-1 gap-6 mb-8 p-8">
      {/* Search and Create Section */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search branches"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border border-white rounded-lg bg-transparent text-white placeholder-gray-400"
        />
        <button
          onClick={handleOpenModal}
          className="bg-teal-500 text-white px-4 py-2 rounded-lg hover:bg-teal-600 transition"
        >
          Create New Branch
        </button>
      </div>

      {/* Branch Details Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredBranches.map((branch) => (
          <div key={branch.id} className="bg-[#4B5563] shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <FaBuilding className="mr-2 text-teal-400" /> {branch.name}
            </h2>
            <div className="mb-4">
              <p className="text-gray-300 flex items-center mb-2">
                <FaUsers className="mr-2 text-indigo-400" /> Employees: {branch.employees}
              </p>
              <p className="text-gray-300 flex items-center mb-2">
                <FaTasks className="mr-2 text-yellow-400" /> Performance: {branch.performance}
              </p>
              <p className="text-gray-300 flex items-center mb-2">
                <FaRegCalendarAlt className="mr-2 text-teal-400" /> Created: {branch.created_at}
              </p>
              <p className="text-gray-300 flex items-center mb-2">
                <FaExclamationTriangle className="mr-2 text-red-400" /> Issues: {branch.issues}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal Component */}
      <CreateBranchModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default Branches;
