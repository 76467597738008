import { useState, useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import { IoFunnelOutline, IoFilterSharp } from "react-icons/io5";
import { FaSearch, FaStar, FaStarHalfAlt, FaRegStar, FaUserTie, FaUser, FaPhoneAlt } from "react-icons/fa";
import { listFeedback, feedBackList } from "../../api";
import PropTypes from "prop-types";
import { Link, useOutletContext } from "react-router-dom";

// Define your API update function
const updateFeedbackToResolved = async (feedbackId) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }

    console.log("Sending feedback_id:", feedbackId);

    const response = await fetch(
      `https://zola.technology/api/company/feedback/update-feedback-to-resolved/?feedback_id=${feedbackId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseText = await response.text();
    if (!response.ok) {
      console.error(
        `HTTP error! Status: ${response.status}, Response: ${responseText}`
      );
      throw new Error(
        `HTTP error! Status: ${response.status} - ${responseText}`
      );
    }

    return JSON.parse(responseText);
  } catch (error) {
    console.error("Error updating feedback to resolved:", error);
    throw error;
  }
};

const FeedbackModal = ({ feedbackInfo, onClose }) => {
  const [resolved, setResolved] = useState(feedbackInfo.resolved || false);
  const [loading, setLoading] = useState(false);

  const handleActionClick = async () => {
    try {
      setLoading(true);
      await updateFeedbackToResolved(feedbackInfo.id);
      setResolved(true);
      toast.success("Negative feedback successfully resolved.");
    } catch (error) {
      toast.error("Failed to resolve the negative feedback.");
    } finally {
      setLoading(false);
    }
  };

  if (!feedbackInfo) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="bg-white text-gray-800 dark:bg-gray-800 dark:text-gray-200 p-8 rounded-xl shadow-xl max-w-lg w-full max-h-full overflow-y-auto border border-gray-200 dark:border-gray-700 animate-fadeIn">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
            Feedback Details
          </h2>
          <button
            onClick={onClose}
            className="text-xl text-gray-400 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 transition duration-300"
          >
            ✕
          </button>
        </div>

        {/* User Info Section */}
        <div className="flex items-center gap-4 mb-6 border-b pb-4 border-gray-200 dark:border-gray-700">
          <FaUser className="text-2xl text-blue-500" />
          <div>
            <p className="font-semibold text-gray-700 dark:text-gray-300">Name:</p>
            <p className="text-gray-900 dark:text-gray-100">
              {feedbackInfo.author_name || "Anonymous"}
            </p>
          </div>
        </div>

        {/* Rating Section */}
        <div className="flex items-center gap-4 mb-6 border-b pb-4 border-gray-200 dark:border-gray-700">
          <FaStar className="text-2xl text-yellow-500" />
          <div>
            <p className="font-semibold text-gray-700 dark:text-gray-300">Rating:</p>
            <StarRating rating={feedbackInfo.rating} />
          </div>
        </div>

        <div className="flex items-center gap-4 mb-6 border-b pb-4 border-gray-200 dark:border-gray-700">
          <FaUserTie className="text-2xl text-teal-500" />
          <div>
            <p className="font-semibold text-gray-700 dark:text-gray-300">
              Employee Name:
            </p>
            <p className="text-gray-900 dark:text-gray-100">
              {feedbackInfo.employee
                ? feedbackInfo.employee.name
                : "No employee details attached"}
            </p>
          </div>
        </div>

        {/* Employee Rating Section */}
        <div className="flex items-center gap-4 mb-6 border-b pb-4 border-gray-200 dark:border-gray-700">
          <FaStar className="text-2xl text-orange-500" />
          <div>
            <p className="font-semibold text-gray-700 dark:text-gray-300">Employee Ratings:</p>
            <StarRating rating={feedbackInfo.employee_rating} />
          </div>
        </div>

        {/* Phone Number Section */}
        <div className="mb-6">
          <FaPhoneAlt className="text-xl text-green-500 inline mr-2" />
          {feedbackInfo.phone_number ? (
            <span className="font-semibold text-gray-900 dark:text-gray-100">
              +{feedbackInfo.phone_number}
            </span>
          ) : (
            <span className="text-gray-600 dark:text-gray-400">No phone number provided</span>
          )}
        </div>

        {/* Action Button for Negative Feedback */}
        {feedbackInfo.rating <= 3 && (
          <button
            onClick={handleActionClick}
            className={`mt-6 w-full py-3 ${
              resolved ? "bg-green-600" : "bg-red-500"
            } text-white rounded-lg shadow-md hover:${
              resolved ? "bg-green-700" : "bg-red-600"
            } transition-all duration-300 ease-in-out transform hover:scale-105`}
            disabled={loading}
          >
            {loading ? "Updating..." : resolved ? "Resolved" : "Take Action"}
          </button>
        )}

        {/* Close Button */}
        <button
          onClick={onClose}
          className="mt-4 w-full py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300 ease-in-out transform hover:scale-105"
        >
          Close
        </button>
      </div>
    </div>
  );
};

FeedbackModal.propTypes = {
  feedbackInfo: PropTypes.shape({
    author_name: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    text_info: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
    resolved: PropTypes.bool, // Add this prop to handle resolved status
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

const StarRating = ({ rating }) => {
  const stars = Array(5).fill(false);

  for (let i = 0; i < rating; i++) {
    stars[i] = true;
  }

  return (
    <div className="flex items-center">
      {stars.map((filled, index) => {
        if (filled) {
          return <FaStar key={index} className="text-yellow-500" />;
        } else if (rating > index && rating < index + 1) {
          return <FaStarHalfAlt key={index} className="text-yellow-500" />;
        } else {
          return <FaRegStar key={index} className="text-yellow-500" />;
        }
      })}
    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

const Feedback = () => {
  const { isExpired } = useOutletContext();

  const [feedbacks, setFeedbacks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("recent");
  const [filterBy, setFilterBy] = useState("all");
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 8;

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        setLoading(true);
        const data =
          filterBy === "all"
            ? await listFeedback(currentPage, pageSize)
            : await feedBackList();

        if (filterBy !== "all") {
          const feedbackData =
            data.categorized_feedbacks[`rating_of_${filterBy}`] ||
            data.categorized_feedbacks.no_rating ||
            [];
          setFeedbacks(feedbackData);
          setTotalPages(1);
        } else {
          setFeedbacks(data.data);
          setTotalPages(data.total_pages);
        }
        console.log(data);
      } catch (error) {
        console.error("Error fetching feedbacks:", error.message);
        setError("Failed to load feedbacks. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, [currentPage, filterBy]);

  const filteredAndSortedFeedbacks = useMemo(() => {
    let filteredFeedbacks = feedbacks;

    if (searchTerm) {
      filteredFeedbacks = filteredFeedbacks.filter((feedback) =>
        feedback.author_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortBy === "recent") {
      return filteredFeedbacks.sort(
        (a, b) => new Date(b.insertion_time) - new Date(a.insertion_time)
      );
    } else if (sortBy === "rating") {
      return filteredFeedbacks.sort((a, b) => b.rating - a.rating);
    }

    return filteredFeedbacks;
  }, [feedbacks, searchTerm, sortBy]);

  const handleSortChange = (value) => {
    setSortBy(value);
    setShowSortOptions(false);
  };

  const handleFilterChange = (value) => {
    setFilterBy(value);
    setShowFilterOptions(false);
  };

  const handleFeedbackClick = (feedbackInfo) => {
    setSelectedFeedback(feedbackInfo);
    setShowFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
    setSelectedFeedback(null);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <section className="flex justify-center items-center h-full">
        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
      </section>
    );
  }

  return (
    <article className={`p-6 bg-white dark:bg-gray-800 text-black dark:text-white rounded-lg shadow-lg`}>
      <div className={`${isExpired ? "blur-[10px]" : ""}`}>

        <div
          className={`flex flex-col sm:flex-row justify-between items-center mb-6`}
        >
          <div className="flex items-center bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 p-2 rounded-full w-full max-w-md">
            <FaSearch className="text-gray-700 dark:text-gray-400" />
            <input
              type="text"
              placeholder="Search..."
              className="ml-2 bg-transparent border-none outline-none w-full text-sm text-black dark:text-white"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="relative flex items-center gap-4 mt-4 sm:mt-0">
            <button
              onClick={() => setShowSortOptions(!showSortOptions)}
              className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
            >
              <IoFilterSharp className="text-gray-700 dark:text-gray-300" />
              <span className="ml-2 text-sm">Sort By</span>
            </button>
            {showSortOptions && (
              <div className="absolute right-0 mt-2 w-40 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg">
                <button
                  onClick={() => handleSortChange("recent")}
                  className="block w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  Recent
                </button>
                <button
                  onClick={() => handleSortChange("rating")}
                  className="block w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  Rating
                </button>
              </div>
            )}

            <button
              onClick={() => setShowFilterOptions(!showFilterOptions)}
              className="flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
            >
              <IoFunnelOutline className="text-gray-700 dark:text-gray-300" />
              <span className="ml-2 text-sm">Filter By</span>
            </button>
            {showFilterOptions && (
              <div className="absolute right-0 mt-2 w-40 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg">
                <button
                  onClick={() => handleFilterChange("all")}
                  className="block w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  All
                </button>
                <button
                  onClick={() => handleFilterChange("positive")}
                  className="block w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  Positive
                </button>
                <button
                  onClick={() => handleFilterChange("neutral")}
                  className="block w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  Neutral
                </button>
                <button
                  onClick={() => handleFilterChange("negative")}
                  className="block w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  Negative
                </button>
              </div>
            )}
          </div>
        </div>
        {filteredAndSortedFeedbacks.length === 0 ? (
          <p className="text-center text-gray-600 dark:text-gray-400">
            No feedback available.
          </p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredAndSortedFeedbacks.map((feedback) => (
              <div
                key={feedback.id}
                className="p-6 bg-gradient-to-br from-blue-100 to-blue-50 dark:from-gray-800 dark:to-gray-900 rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-all duration-300"
                onClick={() => handleFeedbackClick(feedback)}
              >
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                    {feedback.author_name || "Anonymous"}
                  </h3>
                  <div className="flex">
                    <StarRating rating={feedback.rating} />
                  </div>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  {feedback.message}
                </p>
                <p className="mt-4 text-xs text-gray-500 dark:text-gray-400">
                  {new Date(feedback.insertion_time).toLocaleDateString()}
                </p>
              </div>
            ))}
          </div>
        )}

        {totalPages > 1 && (
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-all duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Previous
            </button>
            <span className="text-gray-600 dark:text-gray-300">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-all duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next
            </button>
          </div>
        )}

        {showFeedbackModal && (
          <FeedbackModal
            feedbackInfo={selectedFeedback}
            onClose={handleCloseFeedbackModal}
          />
        )}
      </div>

      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors">
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
      <ToastContainer />
    </article>
  );
};

export default Feedback;
