// /* eslint-disable no-unused-vars */
// import { useState, useEffect, useMemo } from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
// import { axisClasses } from '@mui/x-charts/ChartsAxis';
// import useReviewsData from '../../custom-hook/useReviewsData';

// const BarGraph = () => {
//   const { reviews, loading } = useReviewsData();
//   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
//   const [totalReviews, setTotalReviews] = useState(0);
//   const [previousYearReviews, setPreviousYearReviews] = useState(0);

//   const reviewsForSelectedYear = useMemo(() => (
//     Array.isArray(reviews)
//       ? reviews.filter(review => review.insertionYear === selectedYear)
//       : []
//   ), [reviews, selectedYear]);

//   useEffect(() => {
//     const total = reviewsForSelectedYear.length;
//     setTotalReviews(total);

//     const previousYear = selectedYear - 1;
//     const reviewsForPreviousYear = Array.isArray(reviews)
//       ? reviews.filter(review => review.insertionYear === previousYear)
//       : [];
//     const totalPreviousYear = reviewsForPreviousYear.length;
//     setPreviousYearReviews(totalPreviousYear);
//   }, [reviewsForSelectedYear, reviews, selectedYear]);

//   const reviewCounts = useMemo(() => {
//     const counts = Array.from({ length: 12 }, () => 0);
//     reviewsForSelectedYear.forEach((review) => {
//       counts[review.insertionMonth]++;
//     });
//     return counts;
//   }, [reviewsForSelectedYear]);

//   const data = useMemo(() => (
//     Array.from({ length: 12 }, (_, index) => {
//       const percentage = totalReviews > 0 ? (reviewCounts[index] / totalReviews) * 100 : 0;
//       return {
//         month: new Date(0, index).toLocaleString('default', { month: 'short' }),
//         reviews: percentage,
//       };
//     })
//   ), [reviewCounts, totalReviews]);

//   const chartSetting = {
//     yAxis: [
//       {
//         label: 'Reviews Percentage',
//         min: 0,
//         max: 100,
//         tickValues: [0, 25, 50, 75, 100],
//         tickFormat: (value) => `${value}%`,
//       },
//     ],
//     series: [{ dataKey: 'reviews' }],
//     height: 300,
//     sx: {
//       [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
//         transform: 'translateX(-10px)',
//       },
//     },
//   };

//   const colors = ['#1d4ed8'];

//   return (
//     <div className="p-6 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg dark:shadow-lg">
//       <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">Reviews Count</h3>
//       <div className="flex justify-between items-center mb-4">
//         <div className="flex flex-col">
//           <div className="flex items-center gap-2">
//             <span className="text-3xl font-bold text-gray-800 dark:text-white">{totalReviews}</span>
//           </div>
//         </div>
//         <div className="flex items-center gap-4">
//           <button
//             onClick={() => setSelectedYear(selectedYear - 1)}
//             className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
//           >
//             Previous
//           </button>
//           <span className="text-lg text-gray-800 dark:text-white">Year: {selectedYear}</span>
//           <button
//             onClick={() => setSelectedYear(selectedYear + 1)}
//             className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
//           >
//             Next
//           </button>
//         </div>
//       </div>
//       <span className="text-lg font-medium text-gray-600 dark:text-gray-300 block mb-4">Reviews Count</span>
//       <BarChart
//         dataset={data.length ? data : [{ month: 'Jan', reviews: 0 }]}
//         xAxis={[
//           { scaleType: 'band', dataKey: 'month', tickPlacement: 'middle' },
//         ]}
//         {...chartSetting}
//         colors={colors}
//         height={300}
//       />
//     </div>
//   );
// };

// export default BarGraph;

/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import { LineChart } from '@mui/x-charts/LineChart'; // Import LineChart instead of BarChart
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import useReviewsData from '../../custom-hook/useReviewsData';

const BarGraph = () => {
  const { reviews, loading } = useReviewsData();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [totalReviews, setTotalReviews] = useState(0);
  const [previousYearReviews, setPreviousYearReviews] = useState(0);

  const reviewsForSelectedYear = useMemo(() => (
    Array.isArray(reviews)
      ? reviews.filter(review => review.insertionYear === selectedYear)
      : []
  ), [reviews, selectedYear]);

  useEffect(() => {
    const total = reviewsForSelectedYear.length;
    setTotalReviews(total);

    const previousYear = selectedYear - 1;
    const reviewsForPreviousYear = Array.isArray(reviews)
      ? reviews.filter(review => review.insertionYear === previousYear)
      : [];
    const totalPreviousYear = reviewsForPreviousYear.length;
    setPreviousYearReviews(totalPreviousYear);
  }, [reviewsForSelectedYear, reviews, selectedYear]);

  const reviewCounts = useMemo(() => {
    const counts = Array.from({ length: 12 }, () => 0);
    reviewsForSelectedYear.forEach((review) => {
      counts[review.insertionMonth]++;
    });
    return counts;
  }, [reviewsForSelectedYear]);

  const data = useMemo(() => (
    Array.from({ length: 12 }, (_, index) => {
      const percentage = totalReviews > 0 ? (reviewCounts[index] / totalReviews) * 100 : 0;
      return {
        month: new Date(0, index).toLocaleString('default', { month: 'short' }),
        reviews: percentage,
      };
    })
  ), [reviewCounts, totalReviews]);

  const chartSetting = {
    yAxis: [
      {
        label: 'Reviews Percentage',
        min: 0,
        max: 100,
        tickValues: [0, 25, 50, 75, 100],
        tickFormat: (value) => `${value}%`,
      },
    ],
    series: [{ dataKey: 'reviews' }],
    height: 300,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
  };

  const colors = ['#1d4ed8'];

  return (
    <div className="p-6 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg dark:shadow-lg">
      <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">Reviews Count</h3>
      <div className="flex justify-between items-center mb-4">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <span className="text-3xl font-bold text-gray-800 dark:text-white">{totalReviews}</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => setSelectedYear(selectedYear - 1)}
            className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            Previous
          </button>
          <span className="text-lg text-gray-800 dark:text-white">Year: {selectedYear}</span>
          <button
            onClick={() => setSelectedYear(selectedYear + 1)}
            className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            Next
          </button>
        </div>
      </div>
      <span className="text-lg font-medium text-gray-600 dark:text-gray-300 block mb-4">Reviews Count</span>
      <LineChart
        dataset={data.length ? data : [{ month: 'Jan', reviews: 0 }]}
        xAxis={[
          { scaleType: 'band', dataKey: 'month', tickPlacement: 'middle' },
        ]}
        {...chartSetting}
        colors={colors}
        height={300}
      />
    </div>
  );
};

export default BarGraph;
