import DashboardHeader from "./DashboardHeader";
import Taps from "./charts/Taps";
import BarGraph from "./charts/BarGraph";
import ReviewsChart from "./charts/ReviewsChart";
import { Link, useOutletContext } from "react-router-dom";

const Analytics = () => {
  const { isExpired } = useOutletContext();

  return (
    <div className="p-4">
      <div className={`relative ${isExpired ? "blur-sm" : ""}`}>
        <DashboardHeader title="Analytics" />
        <div className="flex justify-center mt-6">
          <div className="rounded-lg shadow-lg w-full">
            <BarGraph />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 my-8">
          <Taps className="md:col-span-1 lg:col-span-2" />
          <ReviewsChart />
        </div>
      </div>
      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors">
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
