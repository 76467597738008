import React, { useState, useEffect } from "react";
import OverallPerformance from "./OverallPerformance";

const PerformanceDashboard = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Performance",
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const [averageTaps, setAverageTaps] = useState(0);
  const [tapsByDay, setTapsByDay] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState(31);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/?filter=month`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = await response.json();

      // Process the data for the chart
      const labels = result.employees.map((emp) => emp.username);
      const tapsData = result.employees.map((emp) => emp.number_of_taps);
      const bgColor = tapsData.map((_, i) => `rgba(0, 0, 0, ${0.2 + i * 0.1})`);

      setData({
        labels: labels,
        datasets: [
          {
            label: "Performance",
            data: tapsData,
            backgroundColor: bgColor,
          },
        ],
      });

      setAverageTaps((result.total_filtered_taps / result.employees.length).toFixed(2));

      // Determine the number of days in the current month
      const now = new Date();
      const currentMonth = now.getMonth(); // 0-based index for month
      const year = now.getFullYear();
      const lastDay = new Date(year, currentMonth + 1, 0);
      const daysInMonth = lastDay.getDate();
      setDaysInMonth(daysInMonth);

      // Initialize days array
      const tapsByDay = Array(daysInMonth).fill(0);

      // Populate tapsByDay based on the result periods
      result.periods.forEach((period) => {
        period.dates.forEach((date) => {
          const day = new Date(date.date).getDate();
          tapsByDay[day - 1] = period.taps; // Set the taps count for the specific day
        });
      });

      setTapsByDay(tapsByDay);
    };

    fetchData();
  }, []);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const maxTaps = Math.max(...tapsByDay);

  const getShadingClass = (taps) => {
    if (taps > 0) {
      const intensity = Math.floor((taps / maxTaps) * 4); // 4 shades from light to dark
      const shades = [
        "bg-gray-200 dark:bg-blue-200",
        "bg-gray-400 dark:bg-blue-400",
        "bg-gray-600 dark:bg-blue-600",
        "bg-gray-800 dark:bg-blue-800",
        "bg-black dark:bg-blue-900",
      ];
      return shades[intensity];
    }
    return "bg-white dark:bg-gray-800 shadow-md"; // Default white with shadow for days without taps
  };

  // Generate column headers for the days of the week
  const createDayLabels = () => {
    const labels = [];
    for (let i = 0; i < 7; i++) {
      labels.push(daysOfWeek[i]);
    }
    return labels;
  };

  const dayLabels = createDayLabels();

  return (
    <div className="flex flex-col lg:flex-row lg:items-start lg:mt-10 w-full space-y-4 lg:space-y-0 lg:space-x-4">
      <div className="w-full lg:w-3/4 mb-4 lg:mb-0">
        <OverallPerformance />
      </div>
      <div className="w-full lg:w-1/2 bg-gray-100 dark:bg-gray-900 shadow-md p-4">
        <h3 className="mb-4 text-lg font-semibold text-gray-800 dark:text-gray-200">Average Taps</h3>
        <p className="text-2xl font-medium mb-4 text-gray-900 dark:text-gray-100">{averageTaps} Taps</p>
        <h3 className="mb-4 text-lg font-semibold text-gray-800 dark:text-gray-200">This Month</h3>
        <div className="grid grid-cols-7 gap-2 mb-4">
          {/* Column headers for days of the week */}
          {dayLabels.map((day, index) => (
            <div key={index} className="text-center font-medium text-sm lg:text-base text-gray-800 dark:text-gray-300">
              {day}
            </div>
          ))}
          {/* Empty cells for the days before the 1st of the month */}
          {Array.from({ length: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDay() }).map((_, index) => (
            <div key={index} className="w-8 h-8 bg-white dark:bg-gray-800 shadow-md flex items-center justify-center"></div>
          ))}
          {/* Grid cells for each day of the month */}
          {Array.from({ length: daysInMonth }).map((_, index) => (
            <div
              key={index}
              className={`w-8 h-8 ${getShadingClass(tapsByDay[index])} flex items-center justify-center`}
            >
              {/* No numbers displayed here */}
            </div>
          ))}
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center mt-4 text-sm">
          <p className="font-medium mb-2 lg:mb-0 text-gray-800 dark:text-gray-200">Learn about how we count</p>
          <div className="flex items-center">
            <span className="font-medium mr-2 text-gray-800 dark:text-gray-200">No Taps</span>
            <div className="flex space-x-1">
              <div className="w-8 h-8 bg-white dark:bg-gray-800 shadow-md"></div>
              <div className="w-8 h-8 bg-gray-200 dark:bg-blue-200"></div>
              <div className="w-8 h-8 bg-gray-400 dark:bg-blue-400"></div>
              <div className="w-8 h-8 bg-gray-600 dark:bg-blue-600"></div>
              <div className="w-8 h-8 bg-gray-800 dark:bg-blue-800"></div>
              <div className="w-8 h-8 bg-black dark:bg-blue-900"></div>
            </div>
            <span className="font-medium ml-2 text-gray-800 dark:text-gray-200">Taps</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;