import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import Topbar from './topbar/Topbar';

const MainLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  // const navigate = useNavigate();

  
  return (
    <div className="flex flex-col w-full min-h-screen bg-[#374151] text-white">
      <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} setIsClosing={setIsClosing} />

      <main className="flex-1 lg:ml-[300px] my-4">
        <Topbar isClosing={isClosing} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
