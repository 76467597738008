import React, { useState, useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPlacePredictions } from '../../pages/SignUp/googlePlaces';

function CreateBranchModal({ isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    username: '',
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    preferred_business_name: '',
    phone_number: '',
    password: '',
  });
  const [errors, setErrors] = useState({}); // State to hold server errors
  const [loading, setLoading] = useState(false); // State to show loading indicator
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const suggestionsRef = useRef();

  useEffect(() => {
    const { company_name } = formData;
    if (company_name.length > 2) {
      const fetchSuggestions = async () => {
        const suggestions = await fetchPlacePredictions(company_name);
        setPlaceSuggestions(suggestions);
      };
      fetchSuggestions();
    } else {
      setPlaceSuggestions([]);
    }
  }, [formData.company_name]);

  const handlePlaceSelect = (description) => {
    setFormData((prev) => ({
      ...prev,
      company_name: description,
    }));
    setPlaceSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setPlaceSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({}); // Reset errors before submitting

    try {
      const token = localStorage.getItem('token'); // Fetch token from local storage

      // API call to create a new branch
      const response = await fetch('https://zola.technology/api/hq-company/create-branch-company/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData), // Send data as JSON
      });

      if (response.ok) {
        const data = await response.json();
        onSubmit(data); // Call parent component's submit handler
        toast.success('Branch created successfully! Please log in to the new account to complete signup.');
        setTimeout(() => {
          onClose(); // Close modal after a short delay
        }, 3000);
      } else {
        const errorData = await response.json();
        setErrors(errorData); // Display server errors
        toast.error(errors.error)
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
console.log(errors.error)
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-2xl text-white mb-4">Create New Branch</h2>

        <form onSubmit={handleFormSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Input Fields */}
            {Object.keys(formData).map((key) => (
              <div key={key} className="relative mb-4">
                <label className="block text-white capitalize">{key.replace(/_/g, ' ')}</label>
                <input
                  type={key === 'email' ? 'email' : 'text'}
                  name={key}
                  value={formData[key] || ''}
                  onChange={handleInputChange}
                  className="p-2 border border-white rounded-lg bg-transparent text-white w-full"
                />
                {errors[key] && <p className="text-red-500 mt-1">{errors[key][0]}</p>}

                {/* Render place suggestions for company_name */}
                {key === 'company_name' && placeSuggestions.length > 0 && (
                  <div
                    ref={suggestionsRef}
                    className="absolute z-10 bg-white text-black mt-1 rounded-md shadow-md w-full max-h-40 overflow-y-auto"
                  >
                    {placeSuggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handlePlaceSelect(suggestion.description)}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Error Handling */}
          {errors.non_field_errors && (
            <div className="text-red-500 mb-4">{errors.non_field_errors[0]}</div>
          )}

          {/* Form Buttons */}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Create Branch'}
            </button>
          </div>
        </form>

        {/* Toastify Container */}
        <ToastContainer />
      </div>
    </div>
  );
}

export default CreateBranchModal;
