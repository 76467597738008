import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import './stylings/dashboard.css';

function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch("https://zola.technology/api/company/profile/", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        setIsExpired(!result.subscription.status);
      } catch (error) {
        console.log((prevErrors) => ({ ...prevErrors, fetch: error.message }));
      }
    };

    fetchProfileData();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.classList.toggle('dark');
  };

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  return (
    <div className={`flex h-screen ${theme === 'dark' ? 'dark' : ''}`}>
      <aside className={`w-64 text-white fixed inset-y-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-200 ease-in-out md:relative md:translate-x-0 shadow-lg z-10 bg-gray-900 dark:bg-gray-800`}>
        <Sidebar theme={theme} toggleSidebar={toggleSidebar} />
      </aside>
      <div className="flex-1 flex flex-col bg-gray-100 dark:bg-gray-900">
        <Topbar toggleSidebar={toggleSidebar} theme={theme} toggleTheme={toggleTheme} />
        <main className="flex-1 bg-gray-200 dark:bg-gray-700 bg-opacity-70 backdrop-filter backdrop-blur-lg p-4 shadow-inner overflow-y-scroll">
          <Outlet context={{isExpired}} />
        </main>
      </div>
    </div>
  );
}

export default Dashboard;