import { useContext } from "react";
import { FaUsers, FaChartLine, FaExclamationTriangle } from "react-icons/fa";
import { BranchContext } from "../context/BranchContext";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { PieChart } from '@mui/x-charts/PieChart';

const HQAnalytics = () => {
  const { branches } = useContext(BranchContext);

  // Calculate statistics
  const totalEmployees = branches.reduce(
    (total, branch) => total + branch.employees,
    0
  );
  const totalIssues = branches.reduce(
    (total, branch) => total + branch.issues,
    0
  );
  const averagePerformance = (
    branches.reduce(
      (total, branch) => total + parseFloat(branch.performance),
      0
    ) / branches.length
  ).toFixed(2);

  const activeBranches = branches.filter(
    (branch) => branch.status === "Active"
  ).length;
  const pendingBranches = branches.filter(
    (branch) => branch.status === "Pending"
  ).length;

  // Data for the bar chart
  const employeeData = branches.map((branch) => ({
    name: branch.name,
    employees: branch.employees,
  }));

    // Mock Performance Data for Testing
    const mockPerformanceData = [
      { value: 75.3},
      { value: 82.5},
      { value: 68.9},
      { value: 90.4},
      { value: 85.1},
    ];

  // Data for the pie chart
  const performanceData = branches.map((branch, index) => ({
    id: index,
    // value: parseFloat(branch.performance)
    value: mockPerformanceData[index]?.value || 0, // Use the value from mockPerformanceData at the same index
    label: branch.name,
  }));

  console.log(performanceData)

  return (
    <div className="bg-gradient-to-b from-[#1F2937] to-[#2D3748] min-h-screen p-8 text-white">
      <h1 className="text-4xl font-bold mb-6 text-center">HQ Analytics</h1>

      {/* Cards for quick statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
        <div className="bg-gradient-to-b from-[#4B5563] to-[#374151] shadow-lg rounded-lg p-6 hover:scale-105 transform transition duration-300 ease-in-out">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaUsers className="mr-2 text-indigo-400" /> Total Employees
          </h2>
          <p className="text-4xl font-bold">{totalEmployees}</p>
        </div>

        <div className="bg-gradient-to-b from-[#4B5563] to-[#374151] shadow-lg rounded-lg p-6 hover:scale-105 transform transition duration-300 ease-in-out">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaExclamationTriangle className="mr-2 text-red-400" /> Total Issues
          </h2>
          <p className="text-4xl font-bold">{totalIssues}</p>
        </div>

        <div className="bg-gradient-to-b from-[#4B5563] to-[#374151] shadow-lg rounded-lg p-6 hover:scale-105 transform transition duration-300 ease-in-out">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaChartLine className="mr-2 text-green-400" /> Avg Performance
          </h2>
          <p className="text-4xl font-bold">{`${averagePerformance}%`}</p>
        </div>

        <div className="bg-gradient-to-b from-[#4B5563] to-[#374151] shadow-lg rounded-lg p-6 hover:scale-105 transform transition duration-300 ease-in-out">
          <h2 className="text-xl font-semibold mb-4">Active Branches</h2>
          <p className="text-4xl font-bold">{activeBranches}</p>
        </div>

        <div className="bg-gradient-to-b from-[#4B5563] to-[#374151] shadow-lg rounded-lg p-6 hover:scale-105 transform transition duration-300 ease-in-out">
          <h2 className="text-xl font-semibold mb-4">Pending Branches</h2>
          <p className="text-4xl font-bold">{pendingBranches}</p>
        </div>
      </div>

      {/* Bar Chart for Employees per Branch */}
      <div className="mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-green-400">
          Employees per Branch
        </h2>
        <div className="bg-[#2D3748] p-8 rounded-lg shadow-lg">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={employeeData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="name" stroke="#ddd" />
              <YAxis stroke="#ddd" />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#374151",
                  border: "none",
                  borderRadius: "5px",
                }}
              />
              <Legend />
              <Bar
                dataKey="employees"
                fill="#34D399"
                barSize={50}
                radius={[10, 10, 0, 0]}
              >
                <LabelList
                  dataKey="employees"
                  position="top"
                  style={{ fill: "#fff" }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Pie Chart for Branch Performance */}
      <div className="mb-12">
        <h2 className="text-3xl font-bold mb-6 text-center text-pink-400">
          Branch Performance
        </h2>
        <div className="bg-[#2D3748] p-8 rounded-lg shadow-lg">
          <PieChart
            series={[
              {
                data: performanceData,
                innerRadius: 50, // Adjust inner radius for better label spacing
                label: {
                  position: 'outside', // Place labels outside to reduce overlap
                  style: {
                    fontSize: 14, // Adjust font size
                    fill: '#ddd',
                  },
                },
              },
            ]}
            width={800}
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default HQAnalytics;
