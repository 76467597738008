import { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import SkeletonCard from './SkeletonCard';
import { TotalRevenue, PerformanceDashboard } from './TeamPerformance/index';
import { Link, useOutletContext } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function TeamPerformance() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const { isExpired } = useOutletContext();

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("token");
        const month = "month";
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/?filter=${month}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setEmployees(data.employees);
      } catch (error) {
        console.error("Error fetching the employees data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const calculateWeeklyTaps = (periods) => {
    const weekTaps = Array(4).fill(0); // Assuming 4 weeks in a month

    periods.forEach(period => {
      period.dates.forEach(dateObj => {
        const weekNumber = Math.ceil(new Date(dateObj.date).getDate() / 7); // Calculate week number
        weekTaps[weekNumber - 1] += dateObj.taps;
      });
    });

    return weekTaps;
  };

  const calculateMonthlyTaps = (periods) => {
    // Sum all taps from the periods
    return periods.reduce((total, period) => {
      return total + period.dates.reduce((sum, dateObj) => sum + dateObj.taps, 0);
    }, 0);
  };

  const generateData = (periods, isZero) => {
    const weekTaps = calculateWeeklyTaps(periods);
    const labels = ["Week 1", "Week 2", "Week 3", "Week 4"];

    return {
      labels,
      datasets: [
        {
          label: 'Taps per Week',
          data: weekTaps,
          borderColor: isZero ? 'transparent' : 'black',
          backgroundColor: isZero ? 'transparent' : 'rgba(0,0,0,0.5)',
          fill: !isZero,
        },
      ],
    };
  };

  const chartOptions = (isZero, maxTapCount) => ({
    scales: {
      y: {
        min: 0,
        max: isZero ? 5 : maxTapCount + 5,
        ticks: {
          stepSize: 1,
        },
      },
    },
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentItems = employees.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(employees.length / itemsPerPage);

  return (
    <div className="p-4 bg-white text-black dark:bg-gray-800 dark:text-white">
      <div className={`relative ${isExpired ? "blur-sm" : ""}`}>
        <DashboardHeader title="Team Performance" />
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {[...Array(4)].map((_, index) => (
              <SkeletonCard key={index} />
            ))}
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {currentItems.map((employee) => {
                const monthlyTaps = calculateMonthlyTaps(employee.periods); // Get taps for the current month
                return (
                  <div
                    key={employee.id}
                    className="p-4 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 shadow-lg rounded-lg"
                  >
                    <div className="w-full mb-4">
                      <Line
                        data={generateData(employee.periods, monthlyTaps === 0)}
                        options={chartOptions(monthlyTaps === 0, Math.max(...calculateWeeklyTaps(employee.periods)))}
                      />
                    </div>
                    <div className="flex-1">
                      <div className="flex justify-between items-center mb-2">
                        <div>
                          <h3 className="text-sm font-semibold">{employee.username}'s card</h3>
                          <p className="text-xl font-bold">
                            {monthlyTaps} Taps {/* Display monthly taps */}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">This Month</p>
                        </div>
                        <button className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-400">
                          <IoChevronForwardCircleOutline />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  className={`px-4 py-2 mx-1 rounded ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-300 dark:bg-gray-700 text-black dark:text-white'}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </>
        )}
        <PerformanceDashboard />
        <TotalRevenue />
      </div>
      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors">
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamPerformance;
