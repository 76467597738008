import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { getLinkInteractions } from "../../../api";
import "./Taps.css";

function Taps({ className }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("year");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const result = await getLinkInteractions(token, selectedPeriod);
        console.log(result)
        const transformedData = result.data.map((emp) => ({
          name: emp.name,
          taps: emp.filtered_taps_count,
        }));
        setData(transformedData);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [selectedPeriod]);

  const calculatePercentageChange = useMemo(() => {
    if (data.length === 0) return 0;

    const currentPeriodTaps = data.reduce((sum, emp) => sum + emp.taps, 0);
    const previousPeriodTaps = data.length * 100; // Assuming previous period taps are 100 for each employee for simplicity

    if (previousPeriodTaps === 0) {
      return currentPeriodTaps === 0 ? 0 : 100;
    }

    const change =
      ((currentPeriodTaps - previousPeriodTaps) / previousPeriodTaps) * 100;
    return change.toFixed(2);
  }, [data]);

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    // Add logic to fetch data based on the selected period if necessary
  };

  return (
    <div
      className={`p-6 sm:p-8 border border-gray-300 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow-lg text-gray-800 dark:text-white ${className}`}
    >
      <h2 className="text-xl sm:text-2xl font-normal mb-4">Active Cards</h2>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <div className="mb-4 sm:mb-0">
          <div className="flex items-center gap-1">
            <h3 className="text-2xl sm:text-3xl font-normal">Most Taps</h3>
            <span
              className={`bg-gray-200 dark:bg-gray-700 ${
                calculatePercentageChange >= 0
                  ? "text-green-500 dark:text-green-400"
                  : "text-red-500 dark:text-red-400"
              } px-2 py-0.5 rounded`}
            >
              {calculatePercentageChange >= 0 ? "▲" : "▼"}{" "}
              {Math.abs(calculatePercentageChange)}%
            </span>
          </div>
        </div>
        <select
          value={selectedPeriod}
          onChange={handlePeriodChange}
          className="py-1 px-4 text-sm bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white border border-gray-300 dark:border-gray-600 rounded"
        >
          <option>year</option>
          <option>month</option>
          <option>week</option>
          <option>day</option>
        </select>
      </div>
      <span className="text-lg sm:text-xl">Average Reviews Count</span>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
        {data.map((item, index) => (
          <div className="taps-card-item" key={index}>
            <span className="text-gray-800 dark:text-white whitespace-nowrap">
              {item.name}
            </span>
            <div className="flex items-center gap-[0.5rem] w-[100%]">
              <p className="flex items-center bg-gray-200 dark:bg-gray-700 rounded-sm flex-1 m-auto h-[20px]">
                <span
                  className="h-[20px] bg-green-500 dark:bg-green-400 inline-block rounded-sm text-white whitespace-nowrap"
                  style={{ width: `${(item.taps / 100) * 100}%` }}
                ></span>
              </p>
              <span className="text-gray-800 dark:text-white whitespace-nowrap">
                {item.taps}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

Taps.propTypes = {
  className: PropTypes.string,
};

export default Taps;
