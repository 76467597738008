import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title
);

function Reward() {
  const [topUsers, setTopUsers] = useState([]);
  const [otherUsers, setOtherUsers] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviewChange, setReviewChange] = useState("");
  const [rating, setRating] = useState(0);
  const [pastWinners, setPastWinners] = useState([]);
  const [currentWinnerIndex, setCurrentWinnerIndex] = useState(0);
  const [reviewData, setReviewData] = useState({
    labels: [], // User names
    datasets: [
      {
        label: "Reviews Generated",
        data: [], // Individual user review counts
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderWidth: 2,
        fill: true,
      },
    ],
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/rewards/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        const mappedTopUsers = data.data.slice(0, 3).map((user, index) => ({
          name: user.employee.name,
          reviews: user.total_taps,
          position: index === 0 ? "1st" : index === 1 ? "2nd" : "3rd",
          avatar: user.employee.avatar || "https://via.placeholder.com/50",
          color:
            index === 0
              ? "bg-yellow-400"
              : index === 1
              ? "bg-red-400"
              : "bg-blue-400",
        }));

        const mappedOtherUsers = data.data.slice(3).map((user) => ({
          name: user.employee.name,
          reviews: user.total_taps,
          positionChange: user.position_change || "No Change",
        }));

        // Calculate total reviews from all employees
        const total = data.data.reduce((acc, user) => acc + user.total_taps, 0);

        // Prepare data for line chart for individual users
        const reviewsPerUser = data.data; // Assuming each user has their own review data
        const labels = reviewsPerUser.map((user) => user.employee.name); // User names as labels
        const reviewCounts = reviewsPerUser.map((user) => user.total_taps); // Reviews count for each user

        setTopUsers(mappedTopUsers);
        setOtherUsers(mappedOtherUsers);
        setTotalReviews(total); // Update totalReviews here
        setReviewChange(data.review_change || "+0%");
        setRating(data.average_rating || 0);
        setPastWinners([
          {
            name: "John Doe",
            image: "https://via.placeholder.com/150",
            month: "January",
            year: "2023",
          },
          {
            name: "Jane Smith",
            image: "https://via.placeholder.com/150",
            month: "February",
            year: "2023",
          },
          {
            name: "Sam Wilson",
            image: "https://via.placeholder.com/150",
            month: "March",
            year: "2023",
          },
          // Add more winners as needed
        ]);
        setReviewData({
          labels: labels,
          datasets: [
            {
              label: "Reviews Generated",
              data: reviewCounts,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderWidth: 2,
              fill: true,
            },
          ],
        });
      } catch (error) {
        toast.error("Failed to fetch customer data.");
      }
    };

    fetchUserData();
  }, []);

  const reorderedUsers = [
    topUsers[1] || null,
    topUsers[0] || null,
    ...topUsers.slice(2),
  ].filter(Boolean);

  const nextWinner = () => {
    setCurrentWinnerIndex((prevIndex) => (prevIndex + 1) % pastWinners.length);
  };

  const prevWinner = () => {
    setCurrentWinnerIndex(
      (prevIndex) => (prevIndex - 1 + pastWinners.length) % pastWinners.length
    );
  };

  return (
    <div className="flex min-h-screen p-8 dark:bg-gray-900 bg-gray-100">
      <div className="w-2/3 p-6 rounded-xl shadow-md dark:bg-gray-800 bg-white">
        <div className="flex justify-around items-end mb-8 p-12 bg-gray-50 dark:bg-gray-800 rounded-3xl shadow-lg transition-colors duration-300 ease-in-out">
          {reorderedUsers.map((user, index) => (
            <div
              key={index}
              className="flex flex-col gap-4 items-center justify-center p-4 bg-gradient-to-br from-white to-gray-100 dark:from-gray-700 dark:to-gray-600 rounded-2xl shadow-lg transition-transform duration-300 transform hover:scale-105"
            >
              {/* User Avatar */}
              <img
                src={user?.avatar}
                alt={user?.name}
                className="w-24 h-24 mx-auto rounded-full border-4 border-gray-200 dark:border-gray-600 shadow-md mb-2 transition-transform duration-300 hover:scale-110"
              />
              {/* User Name */}
              <p className="text-xl font-semibold text-gray-900 dark:text-white">
                {user?.name}
              </p>

              {/* User Position and Stats Container */}
              <div
                className={`w-40 p-4 text-center rounded-lg shadow-xl transform transition-all duration-300 ease-in-out ${
                  index === 0
                    ? "bg-indigo-500"
                    : index === 1
                    ? "bg-green-500"
                    : "bg-blue-500"
                } ${
                  index === 0
                    ? "hover:bg-indigo-600"
                    : index === 1
                    ? "hover:bg-green-600"
                    : "hover:bg-blue-600"
                }`}
                style={{
                  height:
                    index === 0 ? "250px" : index === 1 ? "300px" : "200px",
                }}
              >
                {/* Position */}
                <p className="text-4xl font-extrabold text-white">
                  {user?.position}
                </p>
                {/* Reviews Generated */}
                <p className="text-sm mt-2 text-gray-200">
                  {user?.reviews} Reviews Generated
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-gray-50 p-4 rounded-lg shadow-sm dark:bg-gray-700 bg-white">
          <h3 className="font-bold text-lg mb-4 text-gray-900 dark:text-white">
            Other Rankings
          </h3>
          <div>
            {otherUsers.map((user, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b py-2"
              >
                <p className="text-gray-700 dark:text-gray-300">{user?.name}</p>
                <p className="text-gray-700 dark:text-gray-300">
                  {user?.reviews} Reviews
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  {user?.positionChange}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-1/3 p-6">
        <div className="p-4 rounded-lg shadow-md dark:bg-gray-800 bg-white dark:text-white">
          <h3 className="font-bold text-md mb-4 text-gray-900 dark:text-white">
            Total Reviews
          </h3>
          <p className="text-4xl font-extrabold">{totalReviews}</p>
          {/* <p className="text-sm mt-2">Change: {reviewChange}</p>
          <p className="text-sm">Average Rating: {rating}</p> */}

          <div className="mt-4 w-full">
            {/* <h3 className="font-bold text-lg mb-4 text-gray-900 dark:text-white">
                      Reviews by User
                    </h3> */}
            <Line data={reviewData} />
          </div>
        </div>

        <div className="mt-8 bg-gradient-to-r from-gray-100 to-gray-50 dark:from-gray-700 dark:to-gray-800 p-6 rounded-3xl shadow-lg dark:shadow-md">
          <div className="flex justify-between items-center mb-6">
            <h3 className="font-extrabold text-2xl text-gray-900 dark:text-white tracking-tight">
              🏆 Past Winners
            </h3>
            {/* <button className="text-blue-500 dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-300 transition-all duration-300 ease-in-out">
              View All
            </button> */}
          </div>

          <div className="relative flex items-center justify-between">
            <button
              onClick={prevWinner}
              className="bg-gray-200 dark:bg-gray-600 p-2 h-8 w-8 text-center flex items-center justify-center rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-gray-500 transition-all duration-300 ease-in-out text-lg"
            >
              &#8592;
            </button>

            {/* Winner Card */}
            <div className="flex flex-col items-center justify-center bg-white dark:bg-gray-800 p-6 rounded-3xl shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105">
              <img
                src={pastWinners[currentWinnerIndex]?.image}
                alt={pastWinners[currentWinnerIndex]?.name}
                className="w-32 h-32 rounded-full mb-4 shadow-lg border-4 border-blue-400 dark:border-blue-600"
              />
              <p className="text-2xl font-bold text-gray-900 dark:text-white">
                {pastWinners[currentWinnerIndex]?.name}
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                {pastWinners[currentWinnerIndex]?.month}{" "}
                {pastWinners[currentWinnerIndex]?.year}
              </p>
            </div>

            <button
              onClick={nextWinner}
              className="bg-gray-200 dark:bg-gray-600 p-2 h-8 w-8 text-center flex items-center justify-center rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-gray-500 transition-all duration-300 ease-in-out text-lg"
            >
              &#8594;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reward;
